import { createQuery } from '@farfetched/core';
import { createWithAuthRequestFx } from '@/shared/api';
import {
    CarDefects,
    CompletionParams,
    Inspection,
    UpdateCarDataParams,
    UpdateDocumentsParams,
    UpdateInfoParams,
} from './types';
import { BrandData, GetRegionsResponse, ModelData, PaginatedResult, UserData } from '@/shared/types';

export const loadInspectionQuery = createQuery({
    effect: createWithAuthRequestFx<number, Inspection>((inspectionId) => ({
        url: `/web/inspections/carData/${inspectionId}`,
        method: 'get',
    })),
});

export const loadDefectsQuery = createQuery({
    effect: createWithAuthRequestFx<number, CarDefects>((inspectionId) => ({
        url: `/web/inspections/defects/${inspectionId}`,
        method: 'get',
    })),
});

export const moveToCompletedQuery = createQuery({
    effect: createWithAuthRequestFx<CompletionParams, void>((params) => ({
        url: '/web/inspections/moveToCompleted',
        method: 'post',
        body: params,
    })),
});

export const loadRatersQuery = createQuery({
    effect: createWithAuthRequestFx<void, PaginatedResult<UserData>>({
        url: '/users',
        method: 'post',
        body: {
            pagination: {
                pageSize: 0,
                current: 1,
            },
        },
    }),
});

export const updateInfoQuery = createQuery({
    effect: createWithAuthRequestFx<UpdateInfoParams, void>(({ id, isOffsite }) => ({
        url: `/web/inspections/updateInspectionInfo/${id}`,
        method: 'post',
        body: { isOffsite },
    })),
});

export const loadBrandsQuery = createQuery({
    effect: createWithAuthRequestFx<void, BrandData[]>({
        url: '/models/brands',
        method: 'get',
    }),
});

export const loadModelsByBrandQuery = createQuery({
    effect: createWithAuthRequestFx<string, ModelData[]>((code) => ({
        url: `/models/models?code=${code}`,
        method: 'get',
    })),
});

export const updateCarDataQuery = createQuery({
    effect: createWithAuthRequestFx<UpdateCarDataParams, void>((params) => ({
        url: '/web/inspections/updateCarData',
        method: 'post',
        body: params,
    })),
});

export const loadRegionsQuery = createQuery({
    effect: createWithAuthRequestFx<void, GetRegionsResponse>({
        url: '/documents/regions',
        method: 'get',
    }),
});

export const updateDocumentsQuery = createQuery({
    effect: createWithAuthRequestFx<UpdateDocumentsParams, void>((params) => ({
        url: '/web/inspections/updateDocuments',
        method: 'post',
        body: params,
    })),
});
