/**
 * Дефекты педального узла.
 */
export enum PedalSwitchDefectType {
    /**
     * Нет.
     */
    NONE = 'NONE',
    /**
     * Дыра.
     */
    HOLE = 'HOLE',
    /**
     * Отсутствует элемент.
     */
    VOID = 'VOID',
    /**
     * Потёртость.
     */
    ATTRITION = 'ATTRITION',
}
