export const MOVE_INSPECTION_TO_NEW = 'MOVE_INSPECTION_TO_NEW';

export const UPDATE_NEW_INSPECTION = 'UPDATE_NEW_INSPECTION';

export const MOVE_INSPECTION_TO_WORK = 'MOVE_INSPECTION_TO_WORK';

export const UPDATE_WORK_INSPECTION = 'UPDATE_WORK_INSPECTION';

export const AUCTION_INSPECTION = 'AUCTION_INSPECTION';

export const UPDATE_CAR_DATA = 'UPDATE_CAR_DATA';

export const UPDATE_DEFECTS = 'UPDATE_DEFECTS';

export const UPDATE_INSPECTION = 'UPDATE_INSPECTION';
