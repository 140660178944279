import styles from './styles.module.css';
import Layout, { Content } from 'antd/es/layout/layout';
import { PropsWithChildren } from 'react';
import { useNavigationModel } from '@/shared/navigation';

export const AuthLayout = ({ children }: PropsWithChildren) => {
    useNavigationModel();

    return (
        <Layout className={styles.authLayout}>
            <Content>{children}</Content>
        </Layout>
    );
};
