/**
 * Тип пользователя.
 */
export class UserType {
    /**
     * Виртуальный пользователь. К примеру для 1С Альфа.
     */
    static VIRTUAL = 'VIRTUAL';

    /**
     * Сотрудник.
     */
    static EMPLOYEE = 'EMPLOYEE';

    /**
     * Клиент.
     */
    static CLIENT = 'CLIENT';

    /**
     * Партнёр.
     */
    static PARTNER = 'PARTNER';

    /**
     * Список всех доступных типов.
     */
    static get list(): UserType[] {
        const { VIRTUAL, EMPLOYEE, CLIENT, PARTNER } = UserType;

        return [
            new UserType(CLIENT, 'Клиент'),
            new UserType(PARTNER, 'Партнёр'),
            new UserType(EMPLOYEE, 'Сотрудник'),
            new UserType(VIRTUAL, 'Virtual'),
        ];
    }

    /**
     * Возвращает label для переданного типа.
     */
    static getLabelById(typeId: string): string {
        const type = UserType.getById(typeId);
        return type ? type.label : 'unknown';
    }

    /**
     * Возвращает объект типа пользователя для переданного ID.
     */
    static getById(typeId: string): UserType {
        return UserType.list.find((type) => type.id === typeId);
    }

    /**
     * Определяет, что переданный id типа, соответствует клиенту.
     */
    static isClient(typeId: string): boolean {
        return typeId === UserType.CLIENT;
    }

    /**
     * Определяет, что переданный id типа, соответствует партнёру.
     */
    static isPartner(typeId: string): boolean {
        return typeId === UserType.PARTNER;
    }

    /**
     * Определяет, что переданный id типа, соответствует сотруднику.
     */
    static isEmployee(typeId: string): boolean {
        return typeId === UserType.EMPLOYEE;
    }

    /**
     * Определяет, что переданный id типа, соответствует виртуальному типу.
     */
    static isVirtual(typeId: string): boolean {
        return typeId === UserType.VIRTUAL;
    }

    constructor(
        readonly id: string,
        readonly label: string,
    ) {}
}
