import React from 'react';
import { Tag } from 'antd';
import { UserType } from '@/shared/constants/user-type';
import PropTypes from 'prop-types';
import { ClientTypeLabel } from './client-type-label';

const PARTNER_LABEL_COLOR = '#47a4d0';
const PARTNER_LABEL_TITLE = 'Партнёр';

export interface UserTypeLabelProps {
    title?: string;
    showType: string | UserType;
    clientType?: {
        title: string;
        color: string;
    };
}

/**
 * Компонент метки отображения типа пользователя.
 */
export const UserTypeLabel = ({ showType, clientType, ...props }: UserTypeLabelProps) => {
    switch (showType) {
        case UserType.CLIENT:
            return <ClientTypeLabel {...props} data={clientType} />;
        case UserType.PARTNER:
            return (
                <Tag {...props} color={PARTNER_LABEL_COLOR}>
                    {PARTNER_LABEL_TITLE}
                </Tag>
            );
        default:
            return null;
    }
};

UserTypeLabel.propTypes = {
    showType: PropTypes.oneOf([UserType.CLIENT, UserType.PARTNER, UserType.EMPLOYEE]),
    clientType: PropTypes.shape({
        title: PropTypes.string,
        color: PropTypes.string,
    }),
};
