import { atom } from '@/shared/factory';
import { createEvent, createStore, sample } from 'effector';
import { getCompletedQuery } from './api';
import { CompletedInspection, GetCompletedInspectionsParams, GetCompletedInspectionsResult } from './types';
import { reshape } from 'patronum';
import { navigationModel } from '@/shared/navigation';
import { createQueryParams } from '@/shared/lib';
import { SorterResult } from 'antd/es/table/interface';
import { persistModel } from '@/shared/persist/model';
import { AppRoute } from '@/shared/routing';

export const COMPLETED_INSPECTIONS_PAGE_SIZE = 10;

const parseQuery = (query: string) => {
    const params = new URLSearchParams(query);
    const { orderBy, orderType, currentPage, pageSize, ...filters } = Object.fromEntries(params.entries());

    return {
        pagination: {
            pageSize: pageSize ?? 10,
            current: currentPage ?? 1,
        },
        sorter: {
            field: orderBy,
            order: orderType,
        },
        filters,
    } as unknown as GetCompletedInspectionsParams;
};

export const completedInspectionsModel = atom(() => {
    const $result = createStore<GetCompletedInspectionsResult>(null);
    const $isLoading = getCompletedQuery.$pending;
    const loadItems = createEvent<GetCompletedInspectionsParams | string | void>();

    sample({
        clock: loadItems,
        source: navigationModel.$query,
        fn: (query: string, params?: GetCompletedInspectionsParams) => params ?? parseQuery(query),
        target: getCompletedQuery.start,
    });

    sample({
        clock: getCompletedQuery.finished.success,
        fn: ({ result }) => result,
        target: $result,
    });

    const { $items, $count, $page } = reshape({
        source: $result,
        shape: {
            $items: (result) => result?.list ?? [],
            $count: (result) => result?.count ?? 1,
            $page: (result) => result?.page ?? 1,
        },
    });

    sample({
        clock: getCompletedQuery.finished.success,
        source: navigationModel.$path,
        fn: (path, { params: { pagination, filters, sorter } }) =>
            path +
            '?' +
            createQueryParams<CompletedInspection>(
                pagination,
                filters,
                sorter as unknown as SorterResult<CompletedInspection>,
            ),
        target: navigationModel.pushFx,
    });

    sample({
        clock: navigationModel.$router,
        filter: (router) => router.location.pathname === AppRoute.InspectionsCompleted,
        fn: (router) => {
            return {
                value: router.location.search,
                key: router.location.pathname,
            };
        },
        target: persistModel.pushFx,
    });

    return {
        $items,
        $count,
        $page,
        $isLoading,
        loadItems,
    };
});
