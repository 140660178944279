import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * OK_COLOR
 */
export const OK_COLOR = '#60cc66';

/**
 * WARNING_COLOR
 */
export const WARNING_COLOR = '#faad14';

/**
 * DANGER_COLOR
 */
export const DANGER_COLOR = '#cc392e';

/**
 * IMPORTANT_COLOR
 */
export const IMPORTANT_COLOR = '#f16c43';

/**
 * EARLY_INSPECTIONS_COLOR
 */
export const EARLY_INSPECTIONS_COLOR = '#456fb4';

/**
 * DANGER_TAG_COLOR
 */
export const DANGER_TAG_COLOR = 'red';

/**
 * DEFECTS_COUNTER_COLOR
 */
export const DEFECTS_COUNTER_COLOR = '#ffae6e';

/**
 * TRUE defect state
 * @type {{color: string, icon: string, title: string}}
 */
export const TRUE_STATE = {
    title: 'OK',
    color: OK_COLOR,
    icon: CheckCircleOutlined,
};

/**
 * FALSE defect state
 * @type {{color: string, icon: string, title: string}}
 */
export const FALSE_STATE = {
    title: 'Не OK',
    color: DANGER_COLOR,
    icon: ExclamationCircleOutlined,
};

/**
 * ТИПЫ ДЕФЕКТОВ
 */

/**
 * Требуется окрас
 */
export const PROP_NEED_REPAINT = 'needRepaint';

/**
 * Окрашенный элемент
 */
export const PROP_PAINTED_ELEMENT = 'paintedElement';

/**
 * Внешние дефекты
 */
export const PROP_EXTERNAL_DEFECTS = 'externalDefects';
/**
 * Не приятный запах
 */
export const PROP_HAS_UNPLEASANT_SMELL = 'hasUnpleasantSmell';
/**
 * Крепления
 */
export const PROP_BINDINGS = 'bindings';
/**
 * Герметик
 */
export const PROP_SEALANT = 'sealant';
/**
 * Потертость
 */
export const PROP_ERASED = 'erased';
/**
 * Состояние подкрылка
 */
export const PROP_INNER_FINDER = 'innerFender';
/**
 * Педальный узел
 */
export const PROP_PEDAL_SWITCH = 'pedalSwitchDefects';

/**
 * Дефекты для проверки отображения
 */
export const DEFECTS_TO_CHECK = [
    PROP_EXTERNAL_DEFECTS,
    PROP_NEED_REPAINT,
    PROP_PAINTED_ELEMENT,
    PROP_HAS_UNPLEASANT_SMELL,
    PROP_BINDINGS,
    PROP_SEALANT,
    PROP_ERASED,
    PROP_INNER_FINDER,
    PROP_PEDAL_SWITCH,
];
