import React, { PropsWithChildren } from 'react';
import { Col, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { ClientType } from '@/shared/types';

const Option = Select.Option;

export interface ClientTypeSelectProps extends PropsWithChildren {
    title?: string;
    typeList: ClientType[];
}

/**
 * Список выбора типа клиента.
 */
export const ClientTypeSelect = ({ typeList, title, ...props }: ClientTypeSelectProps) => {
    return (
        <Select
            {...props}
            showSearch
            allowClear
            placeholder="Выберите тип клиента"
            optionFilterProp="title"
            filterOption={(input, { props }) => props?.title?.toLowerCase()?.includes(input?.toLowerCase())}
        >
            {typeList?.map((item) => (
                <Option key={item.id} title={item.title} value={item.id}>
                    <Row align="middle" gutter={4}>
                        <Col>
                            <div
                                style={{
                                    background: item.color,
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                }}
                            />
                        </Col>
                        <Col>{item.title}</Col>
                    </Row>
                </Option>
            ))}
        </Select>
    );
};

ClientTypeSelect.propTypes = {
    typeList: PropTypes.array,
};
