import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CameraOutlined } from '@ant-design/icons';
import Spin from 'antd/lib/spin';
import { clsx } from 'clsx';
import { BASE_PHOTO_URL, PHOTO_PLACEHOLDER } from '@/shared/constants';
import './style.css';

export interface ImageBoxProps {
    src: string;
    alt?: string;
    amount?: number;
    className: string;
    showImage: (src: string) => void;
}

export const ImageBox = ({ src, className, amount, alt, showImage }: ImageBoxProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    const handleShowImage = () => {
        if (!src || isError) {
            return;
        }
        showImage(src);
    };

    const renderImage = (urlPhoto: string, alt?: string) => {
        return urlPhoto ? (
            <img
                alt={alt}
                src={urlPhoto}
                className="image-box"
                onLoad={() => {
                    setIsLoading(false);
                }}
                onError={() => {
                    setIsLoading(false);
                    setIsError(true);
                }}
            />
        ) : null;
    };

    const renderImageCounter = (isLoading: boolean, amount?: number) => {
        return amount && !isLoading ? (
            <div onClick={handleShowImage} className="image-counter">
                <div>
                    <div className="image-camera">
                        <CameraOutlined />
                    </div>
                    <span className="amount">
                        <span>Ещё&nbsp;</span>
                        <span className="amount-content">{amount}</span>
                        <span>&nbsp;фото</span>
                    </span>
                </div>
            </div>
        ) : null;
    };

    const renderPlaceholder = (urlPhoto: string) => {
        return !urlPhoto || isLoading || isError ? (
            <div className="image-placeholder">
                <Spin spinning={urlPhoto && isLoading}>
                    {!urlPhoto || isError ? (
                        <img className="image-box" alt="Photo placeholder" src={PHOTO_PLACEHOLDER} />
                    ) : null}
                </Spin>
            </div>
        ) : null;
    };

    const urlPhoto = src && BASE_PHOTO_URL + src;

    return (
        <div
            onClick={urlPhoto ? handleShowImage : null}
            className={clsx(
                {
                    'image-with-pointer': Boolean(urlPhoto) && !isError,
                },
                className,
            )}
        >
            {!isError ? renderImage(urlPhoto, alt) : null}
            {renderPlaceholder(urlPhoto)}
            {renderImageCounter(isLoading, amount)}
        </div>
    );
};

ImageBox.propTypes = {
    src: PropTypes.string,
    amount: PropTypes.number,
    className: PropTypes.string,
    showImage: PropTypes.func,
};
