import { TablePaginationConfig } from 'antd';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';

export const createQueryParams = <T>(
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | string | number | null>,
    sorter: SorterResult<T>,
) => {
    const queryParams = new URLSearchParams();
    Object.keys(filters).forEach((prop) => {
        const filteredValue = filters[prop];
        if (Array.isArray(filteredValue)) {
            for (const value of filteredValue) {
                queryParams.append(prop, String(value));
            }
        } else if (filteredValue) {
            queryParams.set(prop, filteredValue.toString());
        }
    });

    if (sorter.order && sorter.field != null) {
        queryParams.set('orderBy', String(sorter.field));
        queryParams.set('orderType', sorter.order);
    }

    queryParams.set('currentPage', String(pagination.current));
    queryParams.set('pageSize', String(pagination.pageSize));

    return queryParams.toString();
};

export const parseQueryParams = (searchPath: string) => {
    const queryParams = new URLSearchParams(searchPath);

    const searchParams = {
        sorter: {
            field: queryParams.get('orderBy'),
            order: queryParams.get('orderType'),
        },
        pagination: {
            current: Number(queryParams.get('currentPage')),
            pageSize: Number(queryParams.get('pageSize')),
        },
        filters: null,
    };

    queryParams.delete('orderBy');
    queryParams.delete('orderType');

    queryParams.delete('currentPage');
    queryParams.delete('pageSize');

    searchParams.filters = [...queryParams.keys()].reduce((acc, key) => {
        acc[key] = queryParams.getAll(key);
        return acc;
    }, {});

    return searchParams;
};

export const mapQueryParamsToFilters = <T extends { key: string }>(
    searchParams: string,
    columns: T[],
    transformValue = null,
) => {
    const { filters, sorter } = parseQueryParams(searchParams);
    const hasTransformValue = typeof transformValue === 'function';

    return columns.map((column) => {
        const valueFromFilters = filters[column.key] || [];
        const filteredValue = hasTransformValue ? transformValue(column.key, valueFromFilters) : valueFromFilters;
        const sortOrder = sorter.field === column.key ? sorter.order : null;

        return { ...column, filteredValue, sortOrder };
    });
};

/**
 * Преобразует в объект в квери параметры со скобками типа field[key]=value
 */
export const objectToQueryString = (obj: object, prefix?: string) => {
    const entries = Object.entries(obj);
    return entries
        .map(([key, value]) => {
            const prefixedKey = prefix ? `${prefix}[${key}]` : key;
            if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                return objectToQueryString(value, prefixedKey);
            } else if (Array.isArray(value)) {
                return value.map((val) => encodeURIComponent(prefixedKey) + '[]=' + encodeURIComponent(val)).join('&');
            } else {
                return encodeURIComponent(prefixedKey) + '=' + encodeURIComponent(value);
            }
        })
        .join('&');
};
