import { PropsWithChildren, ReactElement } from 'react';
import { ConfigProvider } from 'antd';
import { appTheme } from '@/app/theme';

export const AntdConfigProvider = ({ children }: PropsWithChildren): ReactElement => {
    return (
        <ConfigProvider theme={appTheme}>
            <div className="app">{children}</div>
        </ConfigProvider>
    );
};
