import { createMutation } from '@farfetched/core';
import { AuthParams, AuthResultDto } from './types';
import { createApiRequestFx } from '@/shared/api';

export const loginQuery = createMutation({
    effect: createApiRequestFx<AuthParams, AuthResultDto>((body) => ({
        url: '/auth/login',
        method: 'post',
        body,
    })),
});
