import { createQuery } from '@farfetched/core';
import { createWithAuthRequestFx } from '@/shared/api';
import { GetCompletedInspectionsParams, GetCompletedInspectionsResult } from '@/entities/inspection/completed/types';

export const getCompletedQuery = createQuery({
    effect: createWithAuthRequestFx<GetCompletedInspectionsParams, GetCompletedInspectionsResult>((params) => {
        return {
            url: '/web/inspections/completed',
            method: 'post',
            body: params,
        };
    }),
});
