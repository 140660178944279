import { atom } from '@/shared/factory';
import { createEffect, createEvent, sample } from 'effector';
import { message } from 'antd';
import {
    addListener,
    AUCTION_INSPECTION,
    MOVE_INSPECTION_TO_NEW,
    SOCKET_BROADCAST,
    SocketInstance,
    UPDATE_INSPECTION,
} from '@/shared/api';
import { InspectionToday } from '@/entities/inspection';
import { InspectionStatus } from '@/shared/constants';
import { can, RbacAction } from '@/shared/rbac';
import { navigationModel } from '@/shared/navigation';
import { inspectionAuctionNotify, inspectionCompleteNotify } from './ui';

export const notificationsRealtimeModel = atom(() => {
    const init = createEvent();
    const handleBroadcast = createEvent<{ event: string; data: InspectionToday }>();
    const updateInspection = createEvent<{ inspectionId: number; data: { status: InspectionStatus } }>();

    const goToInspection = createEvent<number>();

    const inspectionNewNotifyFx = createEffect(() => {
        void message.info('Доступна новая оценка');
    });

    const inspectionCompleteNotifyFx = createEffect((inspectionId: number) => {
        inspectionCompleteNotify(inspectionId, goToInspection);
    });

    const inspectionAuctionNotifyFx = createEffect(({ id, worksheetNumber }: InspectionToday) => {
        inspectionAuctionNotify(id, worksheetNumber, goToInspection);
    });

    const initSubscriptionsFx = createEffect((socket: SocketInstance) => {
        addListener(socket, UPDATE_INSPECTION, updateInspection);
        addListener(socket, SOCKET_BROADCAST, (event: string, data: InspectionToday) => {
            handleBroadcast({ event, data });
        });
    });

    sample({
        clock: goToInspection,
        fn: (inspectionId) => `/inspections/view/${inspectionId}`,
        target: navigationModel.replaceFx,
    });

    sample({
        clock: init,
        filter: () => can(RbacAction.INSPECTIONS_SUBSCRIBE),
        target: initSubscriptionsFx,
    });

    sample({
        clock: handleBroadcast,
        filter: ({ event }) => event === MOVE_INSPECTION_TO_NEW,
        target: inspectionNewNotifyFx,
    });

    sample({
        clock: handleBroadcast,
        filter: ({ event }) => event === AUCTION_INSPECTION && can(RbacAction.AUCTION_NOTIFICATION),
        fn: ({ data }) => data,
        target: inspectionAuctionNotifyFx,
    });

    sample({
        clock: updateInspection,
        filter: ({ data }) => data?.status === InspectionStatus.COMPLETED,
        fn: ({ inspectionId }) => inspectionId,
        target: inspectionCompleteNotifyFx,
    });

    return {
        init,
    };
});
