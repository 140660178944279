import React from 'react';
import { FALSE_STATE, TRUE_STATE } from '@/shared/constants';

export interface InnerFinderFieldProps {
    data?: boolean;
    hideIcon?: boolean;
}

/**
 * Состояние подкрылка.
 */
export const InnerFinderField = ({ data, hideIcon }: InnerFinderFieldProps) => {
    if (data === undefined || data === null) return null;

    const defect = data === true ? TRUE_STATE : FALSE_STATE;
    return (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Состояние подкрылка:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    );
};

InnerFinderField.defaultProps = {
    hideIcon: false,
};
