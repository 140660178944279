import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import { UserMenu } from './user-menu';
import styles from './styles.module.css';
import { clsx } from 'clsx';

export interface HeaderProps {
    collapsed: boolean;
    onCollapse: () => void;
}

export const Header = ({ collapsed, onCollapse }: HeaderProps) => (
    <Layout.Header className={clsx(styles.appHeader, { [styles.collapsed]: !collapsed })}>
        <div className={styles.collapseTrigger} onClick={onCollapse}>
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
        <UserMenu />
    </Layout.Header>
);
