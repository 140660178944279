import React from 'react';
import { DANGER_COLOR, OK_COLOR, WARNING_COLOR } from '@/shared/constants';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SealantDefectType } from '@/shared/enums';
import { mapSealantDefects } from '@/shared/lib/defects.config';

const SEALANT_DEFECTS = {
    /**
     * Нет.
     */
    [SealantDefectType.NONE]: {
        title: mapSealantDefects(SealantDefectType.NONE),
        color: DANGER_COLOR,
        icon: ExclamationCircleOutlined,
    },
    /**
     * Заводской.
     */
    [SealantDefectType.FACTORY]: {
        title: mapSealantDefects(SealantDefectType.FACTORY),
        color: OK_COLOR,
        icon: CheckCircleOutlined,
    },
    /**
     * Не заводской.
     */
    [SealantDefectType.NOT_FACTORY]: {
        title: mapSealantDefects(SealantDefectType.NOT_FACTORY),
        color: WARNING_COLOR,
        icon: ExclamationCircleOutlined,
    },
};

export interface SealantFieldProps {
    data: keyof typeof SEALANT_DEFECTS;
    hideIcon?: boolean;
}

/**
 * Дефекты герметика.
 */
export const SealantField = ({ data, hideIcon }: SealantFieldProps) => {
    if (!data) return null;

    const defect = SEALANT_DEFECTS[data];
    return defect ? (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Дефекты герметика:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    ) : null;
};

SealantField.defaultProps = {
    hideIcon: false,
};
