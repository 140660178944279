import { atom } from '@/shared/factory';
import { createEffect, createEvent, createStore, restore, sample } from 'effector';
import { CarDefects, Inspection } from './types';
import { loadDefectsQuery, loadInspectionQuery, updateInfoQuery } from './api';
import { reshape } from 'patronum';
import { InspectionStatus } from '@/shared/constants';
import { RequestError } from '@/shared/api';
import { message } from 'antd';
import { navigationModel } from '@/shared/navigation';
import { AppRoute } from '@/shared/routing';

export interface TargetDefect {
    side: string;
    defect: string;
}

export interface SelectedDefect {
    color: string;
    side: string;
    defect: string;
}

export const inspectionModel = atom(() => {
    const loadInspection = loadInspectionQuery.start;
    const inspectionLoaded = loadInspectionQuery.finished.success;
    const updateInspection = createEvent<Inspection>();
    const inspectionLoadingFailed = loadInspectionQuery.finished.failure;
    const loadDefects = loadDefectsQuery.start;
    const updateDefects = createEvent<CarDefects>();
    const defectsLoaded = loadDefectsQuery.finished.success;
    const updateInfo = updateInfoQuery.start;
    const infoUpdated = updateInfoQuery.finished.success;

    const selectDefect = createEvent<SelectedDefect>();
    const setCarModalNotice = createEvent<string>();

    const $inspection = createStore<Inspection>({} as Inspection);
    const $defects = createStore<CarDefects>({} as CarDefects);
    const $selectedDefect = createStore<SelectedDefect>(null);
    const $isLoadingInspection = loadInspectionQuery.$pending;
    const $isLoadingDefects = loadDefectsQuery.$pending;
    const $updatingInfo = updateInfoQuery.$pending;
    const $carModalNotice = restore<string>(setCarModalNotice, null);
    $carModalNotice.reset(loadInspection);

    const showLoadingErrorFx = createEffect((error: RequestError) => {
        console.error(error);
        void message.error('Ошибка загрузки оценки');
    });

    const { $car, $carReview, $documents, $isFullCompleted } = reshape({
        source: $inspection,
        shape: {
            $car: ({ car }) => car,
            $carReview: ({ carReview }) => carReview,
            $documents: ({ documents }) => documents,
            $isFullCompleted: ({ raterStatus, appraiserStatus }) => {
                return raterStatus === InspectionStatus.COMPLETED && appraiserStatus === InspectionStatus.COMPLETED;
            },
        },
    });

    sample({
        clock: inspectionLoadingFailed,
        filter: ({ error }) => error.statusCode === 404,
        fn: () => AppRoute.NotFound,
        target: navigationModel.replaceFx,
    });

    sample({
        clock: inspectionLoadingFailed,
        filter: ({ error }) => error.statusCode !== 404,
        fn: ({ error }) => error,
        target: showLoadingErrorFx,
    });

    sample({
        clock: inspectionLoaded,
        fn: ({ result }) => result,
        target: $inspection,
    });

    sample({
        clock: defectsLoaded,
        fn: ({ result }) => result,
        target: $defects,
    });

    sample({
        clock: selectDefect,
        target: $selectedDefect,
    });

    sample({
        clock: infoUpdated,
        source: $inspection,
        fn: (inspection, { params }) => {
            return { ...inspection, inspectionInfo: { isOffsite: params.isOffsite } };
        },
        target: $inspection,
    });

    return {
        $inspection,
        $car,
        $carReview,
        $documents,
        $defects,
        $selectedDefect,
        $isLoadingInspection,
        $isLoadingDefects,
        $isFullCompleted,
        $carModalNotice,
        $updatingInfo,
        loadInspection,
        inspectionLoaded,
        loadDefects,
        defectsLoaded,
        selectDefect,
        setCarModalNotice,
        updateInfo,
        updateInspection,
        updateDefects,
    };
});
