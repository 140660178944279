import { COMPLETED } from '@/shared/constants/inspection-status';
import { UserType } from '@/shared/constants/user-type';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

/**
 * RBAC правила для Оценщика - СВА (Специалист по выкупу авто).
 */
export const RATER = {
    static: [RbacAction.HOME_PAGE_VISIT, RbacAction.INSPECTION_LIST, RbacAction.CAR_HISTORY_VISIT],
    dynamic: {
        [RbacAction.INSPECTION_COMPLETED_VISIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.PROFILE_VISIT]: ({ data }) => {
            return data && Number(userSession.userId) === Number(data.id);
        },
        [RbacAction.PROFILE_UPDATE]: ({ data }) => {
            return data && Number(data.id) === Number(userSession.userId);
        },
        [RbacAction.STATISTIC_VIEW]: () => {
            const userType = userSession.type;
            return !UserType.isPartner(userType) && !UserType.isClient(userType);
        },
    },
};
