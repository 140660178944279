/**
 * Группа типов коэффициентов.
 */
export class CoefficientGroup {
    static COMMON = {
        id: 'common',
        name: 'Общие',
    };
    static MILEAGE = {
        id: 'mileage',
        name: 'Возраст и пробег',
    };
    static INTERIOR = {
        id: 'interior',
        name: 'Интерьер',
    };
    static EXTERIOR = {
        id: 'exterior',
        name: 'Экстерьер',
    };

    static get all() {
        return [
            CoefficientGroup.COMMON,
            CoefficientGroup.MILEAGE,
            CoefficientGroup.EXTERIOR,
            CoefficientGroup.INTERIOR,
        ];
    }
}
