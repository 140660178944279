import { createWithAuthRequestFx } from '@/shared/api';
import { createQuery } from '@farfetched/core';
import {
    CompletedInspectionToday,
    InspectionToday,
    TodayInspectionsParams,
    TodayInspectionsResult,
    WorkInspectionToday,
} from './types';

export const loadNewInspectionsQuery = createQuery({
    effect: createWithAuthRequestFx<void, TodayInspectionsResult<InspectionToday>>({
        url: '/web/inspections/today',
        method: 'post',
        body: TodayInspectionsParams.forNew(),
    }),
});

export const loadWorkInspectionsQuery = createQuery({
    effect: createWithAuthRequestFx<void, TodayInspectionsResult<WorkInspectionToday>>({
        url: '/web/inspections/today',
        method: 'post',
        body: TodayInspectionsParams.forWork(),
    }),
});

export const loadCompletedInspectionQuery = createQuery({
    effect: createWithAuthRequestFx<number, TodayInspectionsResult<CompletedInspectionToday>>((currentPage) => ({
        url: '/web/inspections/today',
        method: 'post',
        body: TodayInspectionsParams.forCompleted(currentPage),
    })),
});

export const moveToWorkInspectionQuery = createQuery({
    effect: createWithAuthRequestFx<number, WorkInspectionToday>((inspectionId: number) => ({
        url: '/web/inspections/moveToWork',
        method: 'post',
        body: { id: inspectionId },
    })),
});
