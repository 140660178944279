import { Button, DatePicker } from 'antd';
import React from 'react';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';
import PropTypes from 'prop-types';
import dayjs, { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;

const styles = {
    padding: '8px',
    borderRadius: '6px',
    background: '#fff',
    width: '250px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, 0.2)',
};

const mapSelectedKeys = (selectedKeys: string[] | number[], useUnixTime: boolean, defaultFormat: string) => {
    return useUnixTime
        ? selectedKeys.map((date) => dayjs.unix(date))
        : selectedKeys.map((date) => dayjs(date, defaultFormat));
};

const makeOnChange = (
    setSelectedKeys: (selectedKeys: string[] | number[]) => void,
    useUnixTime: boolean,
    defaultFormat: string,
) => {
    return (dates: Dayjs[]) => {
        const [dateFrom, dateTo] = Array.isArray(dates) ? dates : [];
        if (!dateFrom || !dateTo) {
            setSelectedKeys([]);
            return;
        }

        if (useUnixTime) {
            setSelectedKeys([dateFrom?.startOf('date')?.unix(), dateTo?.endOf('date')?.unix()]);
        } else {
            setSelectedKeys([dateFrom?.format(defaultFormat), dateTo?.format(defaultFormat)]);
        }
    };
};

export interface DateRangeFilterProps {
    id: string;
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    clearFilters: () => void;
    displayFormat: string;
    useUnixTime: boolean;
}

/**
 * Компонент фильтра для выбора диапазона дат.
 */
export const DateRangeFilter = ({
    id,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    displayFormat,
    useUnixTime,
}: DateRangeFilterProps) => {
    const onChange = makeOnChange(setSelectedKeys, useUnixTime, displayFormat);
    const defaultValues = mapSelectedKeys(
        selectedKeys,
        useUnixTime,
        displayFormat,
    ) as typeof RangePicker.defaultProps.defaultValue;

    const onReset = () => {
        clearFilters();
        confirm();
    };

    return (
        <div style={styles}>
            <Row>
                <Col span={24}>
                    <RangePicker id={id} format={displayFormat} onChange={onChange} defaultValue={defaultValues} />
                </Col>
            </Row>
            <Row style={{ marginTop: '8px' }}>
                <Col span={24} style={{ display: 'flex' }}>
                    <Button style={{ marginRight: '8px', flex: '1' }} onClick={onReset}>
                        Сбросить
                    </Button>
                    <Button style={{ flex: '1' }} type="primary" onClick={confirm}>
                        Поиск
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

DateRangeFilter.propTypes = {
    id: PropTypes.string,
    setSelectedKeys: PropTypes.func,
    selectedKeys: PropTypes.array,
    confirm: PropTypes.func,
    clearFilters: PropTypes.func,
    displayFormat: PropTypes.string,
    useUnixTime: PropTypes.bool,
};

DateRangeFilter.defaultProps = {
    displayFormat: 'DD-MM-YYYY',
    useUnixTime: true,
};
