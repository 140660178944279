import Avatar from 'antd/es/avatar/avatar';
import Dropdown from 'antd/es/dropdown/dropdown';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import styles from './styles.module.css';
import { useUnit } from 'effector-react/effector-react.umd';
import { sessionModel } from '@/entities/session';
import { userSession } from '@/shared/user-session';
import { Link } from 'react-router-dom';
import { AppRoute } from '@/shared/routing';

export const UserMenu = () => {
    const logout = useUnit(sessionModel.logout);

    return (
        <Space className={styles.userMenu}>
            <Dropdown
                trigger={['click']}
                menu={{
                    items: [
                        {
                            key: 'profile',
                            label: <Link to={AppRoute.getProfileUrl(userSession.userId)}>Профиль</Link>,
                            icon: <UserOutlined />,
                        },
                        {
                            key: 'logout',
                            label: 'Выйти',
                            icon: <LogoutOutlined />,
                            onClick: logout,
                        },
                    ],
                }}
            >
                <Avatar size={38}>{userSession.shortName}</Avatar>
            </Dropdown>
        </Space>
    );
};
