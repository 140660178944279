import { Button, Input } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import Row from 'antd/es/grid/row';
import Col from 'antd/es/grid/col';

const styles = {
    padding: '8px',
    borderRadius: '6px',
    background: '#fff',
    width: '250px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)',
};

export interface TextFilterProps {
    id: string;
    placeholder: string;
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    clearFilters: () => void;
    formatter?: (value: string) => string;
}

/**
 * Компонент фильтра для текстового поля таблицы.
 */
export const TextFilter = ({
    id,
    placeholder,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    formatter,
}: TextFilterProps) => {
    const onReset = () => {
        clearFilters();
        confirm();
    };
    return (
        <div style={styles}>
            <Row>
                <Col span={24} style={{ display: 'flex' }}>
                    <Input
                        id={id}
                        style={{ flex: '1' }}
                        value={selectedKeys[0]}
                        placeholder={placeholder}
                        onChange={({ target }) => {
                            const targetValue = target.value;
                            const selectedValue = !targetValue
                                ? []
                                : [!formatter ? targetValue : formatter(targetValue)];
                            setSelectedKeys(selectedValue);
                        }}
                        onPressEnter={confirm}
                    />
                </Col>
            </Row>
            <Row style={{ marginTop: '8px' }}>
                <Col span={24} style={{ display: 'flex' }}>
                    <Button style={{ marginRight: '8px', flex: '1' }} onClick={onReset}>
                        Сбросить
                    </Button>
                    <Button style={{ flex: '1' }} type="primary" onClick={confirm}>
                        Поиск
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

TextFilter.propTypes = {
    id: PropTypes.string.isRequired,
    setSelectedKeys: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectedKeys: PropTypes.array,
    confirm: PropTypes.func,
    clearFilters: PropTypes.func,
    formatter: PropTypes.func,
};
