import { useNavigationModel } from '@/shared/navigation';
import { Outlet } from 'react-router-dom';

export const EmptyLayout = () => {
    useNavigationModel();

    return (
        <>
            <Outlet />
        </>
    );
};
