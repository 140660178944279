import { CloseCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Row, Select } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import React from 'react';
import PropTypes from 'prop-types';

const { Option } = Select;

const styles = {
    padding: '8px',
    borderRadius: '6px',
    background: '#fff',
    width: '300px',
    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)',
};

export interface MultipleFilterItem {
    id: string;
    text: string;
}

export interface MultipleFilterProps {
    id: string;
    items: MultipleFilterItem[];
    placeholder: string;
    title?: string;
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    clearFilters: () => void;
}

/**
 * Компонент фильтра c множественным выбором.
 */
export const MultipleFilter = ({
    id,
    items,
    placeholder,
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
}: MultipleFilterProps) => {
    const onReset = () => {
        clearFilters();
        confirm();
    };
    return (
        <div style={styles}>
            <Row gutter={8}>
                <Col span={18} style={{ display: 'flex' }}>
                    <Select
                        id={id}
                        allowClear
                        showSearch
                        mode="multiple"
                        style={{ flex: '1' }}
                        value={selectedKeys}
                        autoClearSearchValue
                        placeholder={placeholder}
                        onChange={setSelectedKeys}
                        optionFilterProp="title"
                        filterOption={(input, { props }) => props?.title?.toLowerCase()?.includes(input?.toLowerCase())}
                    >
                        {(items || []).map((item) => (
                            <Option key={item.id} title={item.text}>
                                {item.text}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={6} style={{ display: 'flex' }}>
                    <ButtonGroup>
                        <Button icon={<CloseCircleOutlined />} onClick={onReset} />
                        <Button type="primary" icon={<SearchOutlined />} onClick={confirm} />
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
};

MultipleFilter.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    clearFilters: PropTypes.func.isRequired,
    setSelectedKeys: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectedKeys: PropTypes.array,
    confirm: PropTypes.func,
};
