/**
 * Новая Оценка.
 * @type {number}
 */
export const NEW = 0;

/**
 * Оценка в работе.
 * @type {number}
 */
export const WORK = 1;

/**
 * Завершённая Оценка.
 * @type {number}
 */
export const COMPLETED = 2;

export enum InspectionStatus {
    NEW = 0,
    WORK = 1,
    COMPLETED = 2,
}
