/**
 * Степень износа колеса.
 */
export enum ErasedWheelDefectType {
    /**
     * Высокий.
     */
    HIGHT = 'HIGHT',
    /**
     * Средний.
     */
    MEDIUM = 'MEDIUM',
    /**
     * Малый.
     */
    LOW = 'LOW',
}
