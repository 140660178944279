import React from 'react';
import { Typography } from 'antd';
import './style.css';

const { Text } = Typography;

export interface TextClipboardProps {
    val: string;
    beforeText?: boolean;
    transparent?: boolean;
    whiteColor?: boolean;
    copyCursor?: boolean;
}

/**
 * Компонент отображения текстового значения
 * с возможностью копирования в буфер обмена.
 */
export const TextClipboard = ({
    val,
    beforeText = false,
    transparent = false,
    whiteColor = false,
    copyCursor = true,
}: TextClipboardProps) => {
    return !val ? null : (
        <Text
            copyable
            className={`
                text-clipboard 
                text-clipboard-drop-gap 
                text-clipboard-set-gap 
                ${beforeText ? 'text-clipboard-before-text' : ''} 
                ${transparent ? 'text-clipboard-transparent-icon' : ''} 
                ${whiteColor ? 'text-clipboard-white-color' : ''}
                ${copyCursor ? 'text-clipboard-cursor-copy' : ''} 
           `}
        >
            {val}
        </Text>
    );
};
