import React from 'react';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { DateRangeFilter, TextFilter } from '@/shared/components/common';
import { setFocusOnElement, toLocalNumber } from '@/shared/lib/common';
import { Divisions } from '@/shared/constants/divisions';
import {
    CarCategory,
    COMPLETED,
    CURRENCY_RUB,
    InspectionChannel,
    InspectionStatus,
    PurchaseType,
} from '@/shared/constants';
import { Button, Modal, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { BooleanMark } from './boolean-mark';
import dayjs from 'dayjs';
import { TextClipboard } from './text-clipboard';
import { CheckboxGroupFilter } from './table-filters/checkbox-group-filter';

const { confirm: confirmModal } = Modal;
const statusFilters = [
    {
        text: 'Новая',
        value: InspectionStatus.NEW,
    },
    {
        text: 'В работе',
        value: InspectionStatus.WORK,
    },
    {
        text: 'Завершена',
        value: InspectionStatus.COMPLETED,
    },
];

const getStatusLabel = (status: InspectionStatus) => {
    switch (status) {
        case InspectionStatus.NEW:
            return <Tag color="orange">Новая</Tag>;
        case InspectionStatus.WORK:
            return <Tag color="geekblue">В работе</Tag>;
        case InspectionStatus.COMPLETED:
            return <Tag color="green">Завершена</Tag>;
        default:
            return <Tag>Недоступна</Tag>;
    }
};

/**
 * Компонент отображения даты создания оценки ТС.
 */
const colCreatedAt = ({ fixed = null, rangeProps = {} } = {}) => {
    return {
        title: 'Дата',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        fixed: fixed,
        width: 115,
        render: (_, record) => (
            <div style={{ width: '80px', display: 'inline-block' }}>
                {dayjs.unix(record.createdAt).format('DD-MM-YYYY HH:mm')}
            </div>
        ),
        filterDropdown: (props) => <DateRangeFilter {...props} {...rangeProps} id="createdAtSearch" />,
        onFilterDropdownVisibleChange: setFocusOnElement('createdAtSearch'),
    };
};

/**
 * Компонент отображения ФИО ЭДО (прайсер).
 */
const colAppraiserFullName = () => {
    return {
        title: 'ФИО ЭДО',
        dataIndex: 'appraiserFullName',
        width: 140,
        sorter: true,
        filterDropdown: (props) => <TextFilter {...props} id="appraiserFullNameSearch" placeholder="ФИО ЭДО" />,
        onFilterDropdownVisibleChange: setFocusOnElement('appraiserFullNameSearch'),
    };
};

/**
 * Компонент отображения ФИО СВА (оценщик).
 */
const colRaterFullName = () => {
    return {
        title: 'ФИО СВА',
        dataIndex: 'raterFullName',
        width: 140,
        sorter: true,
        filterDropdown: (props) => <TextFilter {...props} id="raterFullNameSearch" placeholder="ФИО СВА" />,
        onFilterDropdownVisibleChange: setFocusOnElement('raterFullNameSearch'),
    };
};

/**
 * Компонент отображения филиала СВА (оценщика).
 */
const colDivision = ({ fixed = null, width = 180 } = {}) => {
    return {
        title: 'Филиал',
        dataIndex: 'division',
        key: 'division',
        sorter: true,
        fixed: fixed,
        width: width,
        defaultFilteredValue: [],
        render: (_, record) => Divisions.getLabelById(record.division),
        filterDropdown: (props) => <CheckboxGroupFilter {...props} width={width} id="division" placeholder="Филиал" />,
        filters: Divisions.list
            .filter(({ id }) => id !== Divisions.PARTNER && id !== Divisions.CLIENT)
            .map((item) => ({ text: item.label, value: item.id })),
    };
};

/**
 * Компонент отображения VIN номера ТС.
 */
const colVin = ({ fixed = null } = {}) => {
    return {
        title: 'VIN',
        dataIndex: 'vin',
        key: 'vin',
        width: 200,
        sorter: true,
        fixed: fixed,
        className: 'col-vin',
        filterDropdown: (props) => <TextFilter {...props} id="vinSearch" placeholder="VIN" />,
        onFilterDropdownVisibleChange: setFocusOnElement('vinSearch'),
        render: (vin: string) => <TextClipboard val={vin} transparent={true} />,
    };
};

/**
 * Компонент отображения марки ТС.
 */
const colBrand = ({ fixed = null } = {}) => {
    return {
        title: 'Марка',
        dataIndex: 'brand',
        key: 'brand',
        sorter: true,
        fixed: fixed,
        width: 120,
        filterDropdown: (props) => <TextFilter {...props} id="brandSearch" placeholder="Марка" />,
        onFilterDropdownVisibleChange: setFocusOnElement('brandSearch'),
    };
};

/**
 * Компонент отображения модели ТС.
 */
const colModel = ({ fixed = null } = {}) => {
    return {
        title: 'Модель',
        dataIndex: 'model',
        key: 'model',
        sorter: true,
        fixed: fixed,
        width: 130,
        filterDropdown: (props) => <TextFilter {...props} id="modelSearch" placeholder="Модель" />,
        onFilterDropdownVisibleChange: setFocusOnElement('modelSearch'),
    };
};

/**
 * Компонент отображения года ТС.
 */
const colYear = ({ fixed = null } = {}) => {
    return {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
        width: 90,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="yearSearch" placeholder="Год" />,
        onFilterDropdownVisibleChange: setFocusOnElement('yearSearch'),
    };
};

/**
 * Компонент отображения пробега ТС.
 */
const colKilometrage = ({ fixed = null } = {}) => {
    return {
        title: 'Пробег',
        dataIndex: 'kilometrage',
        key: 'kilometrage',
        width: 120,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="kilometrageSearch" placeholder="Пробег" />,
        onFilterDropdownVisibleChange: setFocusOnElement('kilometrageSearch'),
    };
};

const colCustomerPrice = ({ fixed = null } = {}) => {
    return {
        title: 'Цена клиента',
        dataIndex: 'customerPrice',
        key: 'customerPrice',
        width: 130,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="customerPriceSearch" placeholder="Цена клиента" />,
        onFilterDropdownVisibleChange: setFocusOnElement('customerPriceSearch'),
        render: (_, record) => {
            const { customerPrice } = record;
            return customerPrice ? (
                <span>
                    {toLocalNumber(customerPrice)} {CURRENCY_RUB}
                </span>
            ) : (
                '-'
            );
        },
    };
};

/**
 * Компонент отображения закупочной цены ТС.
 */
const colMaxPurchasePrice = ({ fixed = null } = {}) => {
    return {
        title: 'Закупка',
        dataIndex: 'maxPurchasePrice',
        key: 'maxPurchasePrice',
        width: 120,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="maxPurchasePriceSearch" placeholder="Цена закупки" />,
        onFilterDropdownVisibleChange: setFocusOnElement('maxPurchasePriceSearch'),
        render: (_, record) => (
            <span>
                {toLocalNumber(record.maxPurchasePrice)} {CURRENCY_RUB}
            </span>
        ),
    };
};

/**
 * Компонент отображения цены продажи ТС.
 */
const colSalePrice = ({ fixed = null } = {}) => {
    return {
        title: 'Продажа',
        dataIndex: 'salePrice',
        key: 'salePrice',
        width: 130,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="salePriceSearch" placeholder="Цена продажи" />,
        onFilterDropdownVisibleChange: setFocusOnElement('salePriceSearch'),
        render: (_, record) => (
            <span>
                {toLocalNumber(record.salePrice)} {CURRENCY_RUB}
            </span>
        ),
    };
};

/**
 * Компонент отображения стояния "Утиль" ТС.
 */
const colIsJunk = ({ fixed = null } = {}) => {
    return {
        title: 'Утиль',
        dataIndex: 'isJunk',
        key: 'isJunk',
        width: 110,
        sorter: true,
        fixed: fixed,
        render: (_, { isJunk }) => <BooleanMark value={isJunk} />,
        filters: [
            {
                text: 'Утиль',
                value: true,
            },
            {
                text: 'Не утиль',
                value: false,
            },
        ],
        filterMultiple: false,
    };
};

/**
 * Компонент отображения состояния "Вид покупки".
 */
const colPurchaseType = ({ fixed = null } = {}) => {
    return {
        title: 'Вид покупки',
        dataIndex: 'purchaseType',
        key: 'purchaseType',
        width: 120,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <CheckboxGroupFilter {...props} width={180} id="purchaseType" />,
        render: (_, { purchaseType }) => PurchaseType.getLabel(purchaseType),
        filters: PurchaseType.filterableList,
        filterMultiple: true,
    };
};

/**
 * Компонент отображения состояния "Аукционная оценка".
 */
const colIsAuction = ({ fixed = null } = {}) => {
    return {
        title: 'Под аукцион',
        dataIndex: 'isAuction',
        key: 'isAuction',
        width: 120,
        sorter: true,
        fixed: fixed,
        render: (_, { isAuction }) => <BooleanMark value={isAuction} />,
        filterDropdown: (props) => <CheckboxGroupFilter {...props} width={180} id="isAuction" />,
        filterMultiple: false,
        filters: [
            {
                text: 'Да',
                value: true,
            },
            {
                text: 'Нет',
                value: false,
            },
        ],
    };
};

const colActions = (render, { fixed = null } = {}) => {
    return {
        key: 'action',
        justifyContent: 'center',
        width: 70,
        fixed: fixed,
        render:
            render ||
            ((_, record) => (
                <Link to={`/inspections/view/${record.id}`}>
                    <Button size="large" icon={<EyeOutlined />} />
                </Link>
            )),
    };
};

const colRater = ({ fixed = null } = {}) => {
    return {
        title: 'Оценщик',
        dataIndex: 'userLastName',
        key: 'userLastName',
        width: 160,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="userLastNameSearch" placeholder="Оценщик" />,
        onFilterDropdownVisibleChange: setFocusOnElement('userLastNameSearch'),
    };
};

const colAppraiser = ({ fixed = null } = {}) => {
    return {
        title: 'Прайсер',
        dataIndex: 'appraiserLastName',
        key: 'appraiserLastName',
        width: 160,
        sorter: true,
        fixed: fixed,
        filterDropdown: (props) => <TextFilter {...props} id="appraiserLastNameSearch" placeholder="Прайсер" />,
        onFilterDropdownVisibleChange: setFocusOnElement('appraiserLastNameSearch'),
    };
};

/**
 * Компонент отображения столбца удаления оценки.
 */
const colManageActions = (onSuccess, { fixed = null } = {}) => {
    const showConfirm = (id: number) => {
        confirmModal({
            title: 'Удалить оценку?',
            maskClosable: true,
            onOk() {
                onSuccess(id);
            },
        });
    };
    const actions = (data) => <Button danger icon={<DeleteOutlined />} onClick={() => showConfirm(data.id)} />;

    return {
        title: '',
        key: 'actions',
        align: 'center',
        width: 80,
        fixed: fixed,
        render: (_, record) => actions(record),
    };
};

/**
 * Компонент отображения столбца статуса оценки в мобильном приложении.
 */
const colStatus = (editStatus, isCanEdit: boolean, { fixed = null } = {}) => {
    const propertyName = 'status';

    return {
        title: 'Статус в приложении',
        dataIndex: propertyName,
        key: propertyName,
        width: 160,
        sorter: true,
        fixed: fixed,
        filters: statusFilters,
        render: (_, record) => {
            const canShowButton = isCanEdit && record.status < COMPLETED;
            return (
                <div className="inspection-col-status-container">
                    <div className="inspection-col-status">{getStatusLabel(record[propertyName])}</div>
                    <div className="inspection-col-status-btn">
                        {canShowButton ? (
                            <Button
                                size="small"
                                shape="circle"
                                icon={<EditOutlined />}
                                onClick={() => editStatus(record)}
                            />
                        ) : null}
                    </div>
                </div>
            );
        },
    };
};

/**
 * Компонент отображения столбца статуса оценки в веб-приложении.
 */
const colAppraiserStatus = ({ fixed = null } = {}) => {
    const propertyName = 'appraiserStatus';
    return {
        title: 'Статус проверки ТС',
        dataIndex: propertyName,
        key: propertyName,
        width: 160,
        sorter: true,
        fixed: fixed,
        filters: statusFilters,
        render: (_, record) => (
            <div className="inspection-col-status-container">
                <div className="inspection-col-status">{getStatusLabel(record[propertyName])}</div>
            </div>
        ),
    };
};

const colWorksheetNumber = ({ fixed = null } = {}) => {
    return {
        title: '№ РЛП',
        dataIndex: 'worksheetNumber',
        key: 'worksheetNumber',
        sorter: true,
        fixed: fixed,
        width: 140,
        filterDropdown: (props) => <TextFilter {...props} id="worksheetNumber" placeholder="Номер РЛП" />,
        onFilterDropdownVisibleChange: setFocusOnElement('worksheetNumber'),
    };
};

/**
 * Компонент отображения столбца категории авто.
 */
const colCategory = ({ fixed = null } = {}) => {
    return {
        title: 'Категория',
        dataIndex: 'category',
        key: 'category',
        width: 135,
        sorter: true,
        fixed: fixed,
        render: (category) => {
            const { color, name } = CarCategory.getByName(category);
            return <Tag color={color}>{name}</Tag>;
        },
        filters: CarCategory.all.map(({ name }) => ({ text: name, value: name })),
    };
};

/**
 * Компонент отображения столбца "Канал" авто.
 */
const colChannel = ({ fixed = null } = {}) => {
    return {
        title: 'Канал',
        dataIndex: 'channel',
        key: 'channel',
        width: 110,
        sorter: true,
        fixed: fixed,
        render: (channel: string) => {
            const { color, name } = InspectionChannel.getByKey(channel);
            return <Tag color={color}>{name}</Tag>;
        },
        filterDropdown: (props) => <CheckboxGroupFilter {...props} width={180} id="purchaseType" />,
        filters: InspectionChannel.all.map(({ key, name }) => ({ text: name, value: key })),
        filterMultiple: false,
    };
};

/**
 * Компонент отображения столбца "Выездная".
 */
const colIsOffsiteInspection = ({ fixed = null } = {}) => {
    return {
        title: 'Выездная',
        dataIndex: 'isOffsite',
        key: 'isOffsite',
        width: 135,
        sorter: true,
        fixed: fixed,
        render: (_, { isOffsite }) => <BooleanMark value={Boolean(isOffsite)} />,
        filterDropdown: (props) => <CheckboxGroupFilter {...props} width={180} id="isOffsite" />,
        filters: [
            {
                text: 'Выездная',
                value: true,
            },
            {
                text: 'Невыездная',
                value: false,
            },
        ],
        filterMultiple: false,
    };
};

export {
    colCreatedAt,
    colAppraiserFullName,
    colRaterFullName,
    colDivision,
    colVin,
    colBrand,
    colModel,
    colYear,
    colKilometrage,
    colCustomerPrice,
    colMaxPurchasePrice,
    colSalePrice,
    colIsJunk,
    colActions,
    colRater,
    colAppraiser,
    colStatus,
    colAppraiserStatus,
    colWorksheetNumber,
    colManageActions,
    colCategory,
    colChannel,
    colIsOffsiteInspection,
    colPurchaseType,
    colIsAuction,
};
