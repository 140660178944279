import { Divisions } from '../constants';
import { SessionData } from './session-data';

const SESSION_DATA_KEY = 'sessionData';

class UserSession {
    private sessionData: SessionData | null = null;

    get data(): SessionData | null {
        if (!this.sessionData) {
            const rawData = localStorage.getItem(SESSION_DATA_KEY);
            this.sessionData = !rawData ? null : SessionData.fromJson(rawData);
        }
        return this.sessionData;
    }

    get authToken(): string {
        return this.data?.authToken;
    }

    get isActive(): boolean {
        return Boolean(this.authToken);
    }

    get role(): string {
        return this.data?.role.id;
    }

    get userId(): number {
        return this.data?.id;
    }

    get type(): string {
        return this.data?.type as unknown as string;
    }

    get userName(): string {
        return this.data?.userName;
    }

    get shortName(): string {
        return this?.userName?.[0]?.toUpperCase();
    }

    get division(): Divisions {
        return this.data?.division;
    }

    get isPartner(): boolean {
        return this.data?.isPartner;
    }

    save(data: SessionData): this {
        this.sessionData = data;
        localStorage.setItem(SESSION_DATA_KEY, data.toJson());
        return this;
    }

    clear(): void {
        this.sessionData = null;
        localStorage.clear();
    }
}

export const userSession = new UserSession();
