import { COMPLETED } from '@/shared/constants/inspection-status';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

/**
 * RBAC правила для Прайсера.
 */
export const APPRAISER = {
    static: [
        RbacAction.HOME_PAGE_VISIT,
        RbacAction.INSPECTION_LIST,
        RbacAction.INSPECTION_VISIT,
        RbacAction.INSPECTIONS_TODAY_VISIT,
        RbacAction.INSPECTIONS_SUBSCRIBE,
        RbacAction.STATISTIC_VIEW,
        RbacAction.CAR_CATEGORY_VISIT,
        RbacAction.CAR_HISTORY_VISIT,
    ],
    dynamic: {
        [RbacAction.PROFILE_VISIT]: ({ data }) => {
            return data && Number(userSession.userId) === Number(data.id);
        },
        [RbacAction.PROFILE_UPDATE]: ({ data }) => {
            return data && Number(data.id) === Number(userSession.userId);
        },
        [RbacAction.INSPECTION_DOCUMENTS_EDIT]: (status: number) => {
            return status === COMPLETED;
        },
        [RbacAction.INSPECTION_INSPECTION_INFO_UPDATE]: (appraiserId: number) => {
            return userSession.userId === appraiserId;
        },
        [RbacAction.INSPECTION_CAR_EDIT]: (status: number) => {
            return status === COMPLETED;
        },
    },
};
