import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { DataPlaceholder } from './data-placeholder';
import { DANGER_COLOR, OK_COLOR } from '@/shared/constants';

export interface ItemLabelProps {
    format?: boolean;
    labelOk: string;
    labelNotOk: string;
    value: true | false | string | ReactNode | null;
    customClass?: string;
}

/**
 * Компонент отображения лейбла.
 */
export const ItemLabel = ({ format, labelOk, labelNotOk, value, customClass }: ItemLabelProps) => {
    const className = `car-data-item-value ${customClass || ''}`;

    if (value === null) {
        return DataPlaceholder;
    }

    if (format) {
        return value === true ? (
            <span style={{ color: OK_COLOR }} className={className}>
                {labelOk}
            </span>
        ) : (
            <span style={{ color: DANGER_COLOR }} className={className}>
                {labelNotOk}
            </span>
        );
    }

    return <span className={className}>{value}</span>;
};

ItemLabel.propTypes = {
    value: PropTypes.any,
    format: PropTypes.bool,
    labelOk: PropTypes.string,
    labelNotOk: PropTypes.string,
};

ItemLabel.defaultProps = {
    labelOk: 'OK',
    labelNotOk: 'Не OK',
};
