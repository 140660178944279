import React from 'react';
import { Helmet } from 'react-helmet';
import { Workspace, EmptyLayout } from '@/app/layouts';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { AuthPage } from '@/pages/auth';
import { AppRoute, ErrorBoundary } from '@/shared/routing';
import { checkSessionLoader } from './check-session-loader';

const NOT_FOUND_ROUTE: RouteObject = {
    path: '*',
    async lazy() {
        const { NotFoundPage } = await import('@/pages/not-found');
        return { Component: NotFoundPage };
    },
};

const routes = createBrowserRouter([
    {
        path: AppRoute.Auth,
        element: <AuthPage />,
        errorElement: <ErrorBoundary />,
        loader: checkSessionLoader,
    },
    // Страница без лайоута (для печати)
    {
        element: <EmptyLayout />,
        errorElement: <ErrorBoundary />,
        loader: checkSessionLoader,
        children: [
            {
                path: AppRoute.InspectionPrint,
                async lazy() {
                    const { InspectionPrintPage } = await import('@/pages/inspections/print');
                    return { Component: InspectionPrintPage };
                },
            },
        ],
    },

    {
        element: <Workspace />,
        errorElement: <ErrorBoundary />,
        loader: checkSessionLoader,
        children: [
            {
                path: AppRoute.Root,
                async lazy() {
                    const { HomePage } = await import('@/pages/home');
                    return { Component: HomePage };
                },
            },
            {
                path: AppRoute.Home,
                async lazy() {
                    const { HomePage } = await import('@/pages/home');
                    return { Component: HomePage };
                },
            },

            // Users
            {
                path: AppRoute.Profile,
                async lazy() {
                    const { UserProfilePage } = await import('@/pages/user-profile');
                    return { Component: UserProfilePage };
                },
            },
            {
                path: AppRoute.Users,
                async lazy() {
                    const { UsersPage } = await import('@/pages/management/users');
                    return { Component: UsersPage };
                },
            },
            // Clients
            {
                path: AppRoute.Clients,
                async lazy() {
                    const { ClientsPage } = await import('@/pages/management/clients');
                    return { Component: ClientsPage };
                },
            },
            {
                path: AppRoute.ClientProfile,
                async lazy() {
                    const { ClientProfilePage } = await import('@/pages/management/clients/profile');
                    return { Component: ClientProfilePage };
                },
            },

            // Inspections
            {
                path: AppRoute.InspectionsCompleted,
                async lazy() {
                    const { CompletedInspectionsPage } = await import('@/pages/inspections/completed');
                    return { Component: CompletedInspectionsPage };
                },
            },
            {
                path: AppRoute.InspectionsManage,
                async lazy() {
                    const { InspectionsManagementPage } = await import('@/pages/management/inspections');
                    return { Component: InspectionsManagementPage };
                },
            },
            {
                path: AppRoute.InspectionsToday,
                async lazy() {
                    const { InspectionsTodayPage } = await import('@/pages/inspections/today');
                    return { Component: InspectionsTodayPage };
                },
            },
            {
                path: AppRoute.InspectionView,
                async lazy() {
                    const { InspectionViewPage } = await import('@/pages/inspections/view');
                    return { Component: InspectionViewPage };
                },
            },
            // CarHistory
            {
                path: AppRoute.CarHistory,
                async lazy() {
                    const { CarHistoryPage } = await import('@/pages/analytics');
                    return { Component: CarHistoryPage };
                },
            },
            // Statistics
            {
                path: AppRoute.CommonStatistic,
                async lazy() {
                    const { CommonStatisticsPage } = await import('@/pages/statistics/common');
                    return { Component: CommonStatisticsPage };
                },
            },
            {
                path: AppRoute.InspectionStatistic,
                async lazy() {
                    const { InspectionsStatisticPage } = await import('@/pages/statistics/inspections');
                    return { Component: InspectionsStatisticPage };
                },
            },
            // ScreensEditor
            {
                path: AppRoute.ScreensEditor,
                async lazy() {
                    const { ScreensEditorPage } = await import('@/pages/management/screens');
                    return { Component: ScreensEditorPage };
                },
            },
            // AuctionManagement
            {
                path: AppRoute.AuctionManagement,
                async lazy() {
                    const { AuctionManagementPage } = await import('@/pages/management/auction');
                    return { Component: AuctionManagementPage };
                },
            },
            // CategoryManagement
            {
                path: AppRoute.CategoryManagement,
                async lazy() {
                    const { CategoryManagementPage } = await import('@/pages/management/categories');
                    return { Component: CategoryManagementPage };
                },
            },
            // CarCategory
            {
                path: AppRoute.CarCategory,
                async lazy() {
                    const { CarCategoryPage } = await import('@/pages/car-category');
                    return { Component: CarCategoryPage };
                },
            },
            // InspectionBids
            {
                path: AppRoute.InspectionBids,
                async lazy() {
                    const { InspectionsBidPage } = await import('@/pages/inspection-bid');
                    return { Component: InspectionsBidPage };
                },
            },
            // Forbidden
            {
                path: AppRoute.Forbidden,
                async lazy() {
                    const { ForbiddenPage } = await import('@/pages/forbidden');
                    return { Component: ForbiddenPage };
                },
            },

            NOT_FOUND_ROUTE,
        ],
    },

    NOT_FOUND_ROUTE,
]);

export const AppRouter = () => (
    <>
        <Helmet titleTemplate="%s &middot; AutoInspect" defaultTitle="AutoInspect" />
        <RouterProvider router={routes} />
    </>
);
