import { CarDefects, Inspection } from '@/entities/inspection';
import { CarDataRealtimeInfo, DefectsRealtimeInfo } from './types';
import { CarDataEntityName } from './enums';

export const filterByInspection = ({ inspectionId }: Inspection, event: { inspectionId: number }) => {
    return inspectionId === event.inspectionId;
};

export const reducerForCarDataUpdate = (
    inspection: Inspection,
    { data, entityName }: CarDataRealtimeInfo,
): Inspection => {
    switch (entityName) {
        case CarDataEntityName.SelfDiagnostic:
            return {
                ...inspection,
                diagnostics: { ...inspection.diagnostics, ...data },
            };
        case CarDataEntityName.Documents:
            return {
                ...inspection,
                documents: { ...inspection.documents, ...data },
            };
        case CarDataEntityName.Advantage:
            return {
                ...inspection,
                advantage: { ...inspection.advantage, ...data },
            };
        case CarDataEntityName.TestDrive:
            return {
                ...inspection,
                testDrive: { ...inspection.testDrive, ...data },
            };
        default:
            return { ...inspection, [entityName]: { ...data } };
    }
};

export const reducerForDefectsUpdate = (
    defects: CarDefects,
    { data, rating, entityName }: DefectsRealtimeInfo,
): CarDefects => {
    if (rating && rating.bodyRating && rating.salonRating) {
        return {
            ...defects,
            [entityName]: { ...data },
            carBodyRating: rating.bodyRating,
            salonRating: rating.salonRating,
        };
    }

    return { ...defects, [entityName]: { ...data } };
};
