import io from 'socket.io-client';

export type SocketInstance = typeof io;

export const INIT_SESSION = 'INIT_SESSION';
export const SOCKET_BROADCAST = 'broadcast';
export const SOCKET_CONNECT = 'connect';
export const SOCKET_ERROR = 'error';

let socket: SocketInstance = null;

export const createSocket = (uri?: string) => {
    if (!socket) {
        socket = io(uri ?? import.meta.env.VITE_APP_SOCKET_GATEWAY, { transports: ['websocket'] });
    }

    return socket;
};

export const initSocket = (authToken: string) => {
    if (!authToken) {
        throw new Error('Для инициализации сокета необходим токен аутентификации');
    }

    return new Promise((resolve, reject) => {
        const socket = createSocket()
            .on(SOCKET_ERROR, reject)
            .on(SOCKET_CONNECT, () => {
                socket.emit(INIT_SESSION, { authToken }, (clientId: string) => {
                    if (socket.id === clientId) {
                        resolve(socket);
                    } else {
                        reject(new Error('Ошибка инициализации сокета'));
                    }
                });
            });
    });
};

export const closeSocket = () => {
    if (socket) {
        socket.removeAllListeners();
        socket.close();
        socket = null;
    }
};

export const addListener = (socket: SocketInstance, event: string, listener: (...args: any[]) => void) => {
    if (socket) {
        socket.on(event, listener);
    }
};

export const addListenerOnce = (socket: SocketInstance, event: string, listener: (...args: any[]) => void) => {
    if (!socket?.hasListeners(event)) {
        socket.on(event, listener);
    }
};
