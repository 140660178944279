import { CreateRequestParams, Payload, RequestError, RequestInstanceParams, RequestParams } from './types';
import { createEffect } from 'effector';
import { BASE_STATISTICS_URL, BASE_URL, createRequestInstance } from './request';

function createRequestInstanceFx<P = RequestParams, R = void, E = RequestError>(
    instanceParams: RequestInstanceParams<P>,
) {
    return createEffect<P, R, E>(createRequestInstance(instanceParams));
}

function createRequestFx(params: CreateRequestParams) {
    return <P = RequestParams, R = void, E = RequestError>(payload: Payload<P>) => {
        return createRequestInstanceFx<P, R, E>({ ...(params as RequestParams), payload });
    };
}

export const createApiRequestFx = createRequestFx({
    baseURL: BASE_URL,
});

export const createWithAuthRequestFx = createRequestFx({
    baseURL: BASE_URL,
    withAuth: true,
});

export const createExternalStatRequestFx = createRequestFx({
    baseURL: BASE_STATISTICS_URL,
});
