import React from 'react';
import { DANGER_COLOR, OK_COLOR } from '@/shared/constants';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * Есть неприятный запах.
 */
export const TRUE_UNPLEASANT_STATE = {
    title: 'Да',
    color: DANGER_COLOR,
    icon: ExclamationCircleOutlined,
};

/**
 * Нет неприятного запаха.
 */
export const FALSE_UNPLEASANT_STATE = {
    title: 'Нет',
    color: OK_COLOR,
    icon: CheckCircleOutlined,
};

export interface HasUnpleasantSmellFieldProps {
    data?: boolean;
    hideIcon?: boolean;
}

/**
 * Наличие неприятного запаха.
 */
export const HasUnpleasantSmellField = ({ data, hideIcon }: HasUnpleasantSmellFieldProps) => {
    if (data === undefined || data === null) return null;

    const defect = data === true ? TRUE_UNPLEASANT_STATE : FALSE_UNPLEASANT_STATE;
    return (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Неприятный запах:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    );
};

HasUnpleasantSmellField.defaultProps = {
    hideIcon: false,
};
