import React, { CSSProperties } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

const defaultClientData = { title: 'Клиент', color: '#d0733d' };

export interface ClientTypeLabelProps {
    data: {
        title: string;
        color: string;
    };
    style?: CSSProperties;
}

/**
 * Лейбл типа клиента.
 */
export const ClientTypeLabel = ({ data, style, ...props }: ClientTypeLabelProps) => {
    const { color, title } = data || {};
    return (
        <Tag {...props} style={style} color={color || defaultClientData.color}>
            {!title ? defaultClientData.title : 'Клиент: ' + title}
        </Tag>
    );
};

ClientTypeLabel.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        color: PropTypes.string,
    }),
};
