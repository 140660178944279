import { atom } from '@/shared/factory';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { SessionData, userSession } from '@/shared/user-session';
import { loginQuery } from './api';
import { navigationModel } from '@/shared/navigation';
import { AppRoute } from '@/shared/routing';
import { not } from 'patronum';

export const sessionModel = atom(() => {
    const logout = createEvent();
    const redirectToAuth = createEvent();
    const redirectToHome = createEvent();
    const $isLoggedIn = createStore(false);
    $isLoggedIn.reset(logout);

    const saveSessionFx = createEffect((data: SessionData) => {
        return userSession.save(data);
    });

    const clearSessionFx = createEffect(() => {
        return userSession.clear();
    });

    sample({
        clock: loginQuery.finished.success,
        fn: ({ result }) => SessionData.from(result),
        target: saveSessionFx,
    });

    sample({
        clock: saveSessionFx.doneData,
        fn: ({ isActive }) => isActive,
        target: [$isLoggedIn, redirectToHome],
    });

    sample({
        clock: redirectToHome,
        fn: () => AppRoute.Home,
        target: navigationModel.replaceFx,
    });

    sample({
        clock: redirectToAuth,
        fn: () => AppRoute.Auth,
        target: navigationModel.replaceFx,
    });

    sample({
        clock: navigationModel.$path,
        fn: () => userSession.isActive,
        target: $isLoggedIn,
    });

    sample({
        clock: navigationModel.$path,
        filter: not($isLoggedIn),
        target: [logout, redirectToAuth],
    });

    sample({
        clock: logout,
        target: clearSessionFx,
    });

    sample({
        clock: clearSessionFx.done,
        target: redirectToAuth,
    });

    return {
        $isLoggedIn,
        logout,
    };
});
