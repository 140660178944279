import React from 'react';

export const DataPlaceholder = (
    <span
        style={{
            display: 'inline-block',
            color: '#ccc',
            whiteSpace: 'nowrap',
        }}
    >
        Нет данных
    </span>
);
