import { ADMIN } from './admin';
import { APPRAISER_MANAGER } from './appraiser-manager';
import { RATER_MANAGER } from './rater-manager';
import { APPRAISER } from './appraiser';
import { RATER } from './rater';
import { WATCHER } from './watcher';
import { INVITED_RATER } from './invited-rater';
import { RbacAction } from './actions';

export { RbacAction };

export type DynamicParams = Record<string, any> | number | string;

export interface PermissionRule<T = DynamicParams> {
    static: RbacAction[];
    dynamic: Record<string, (params?: T) => boolean>;
}

/**
 * RBAC правила.
 */
export const rules: Record<string, PermissionRule> = {
    ADMIN,
    APPRAISER_MANAGER,
    RATER_MANAGER,
    APPRAISER,
    RATER,
    WATCHER,
    INVITED_RATER,
};
