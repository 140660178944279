import React from 'react';
import { Tag } from 'antd';
import { DANGER_COLOR, DANGER_TAG_COLOR, OK_COLOR } from '@/shared/constants';
import { getDefectLabelIcon } from '@/shared/components/common/defects/defect-labels/defect-label';
import { PaintedElementDefectType } from '@/shared/enums';
import { mapPaintedElementDefects } from '@/shared/lib/defects.config';

export interface PaintedElementFieldProps {
    data?: PaintedElementDefectType[];
    hideIcon?: boolean;
}

/**
 * Дефекты окрашенного элемента.
 */
export const PaintedElementField = ({ data, hideIcon }: PaintedElementFieldProps) => {
    if (!data) return null;

    const hasDefects = data.length && !data.includes(PaintedElementDefectType.NONE);
    const color = hasDefects ? DANGER_COLOR : OK_COLOR;

    return (
        <div className="defect-field-item">
            {!hideIcon && getDefectLabelIcon(hasDefects, color)}
            <span className="defect-item-title">Окрашенный элемент:&nbsp;</span>
            {hasDefects ? (
                data.map((item) => (
                    <Tag
                        key={item}
                        color={DANGER_TAG_COLOR}
                        style={{
                            marginRight: '2px',
                            fontSize: '11px',
                            lineHeight: '14px',
                        }}
                    >
                        {mapPaintedElementDefects(item)}
                    </Tag>
                ))
            ) : (
                <span className="defect-item-value" style={{ color }}>
                    Отсутствует
                </span>
            )}
        </div>
    );
};

PaintedElementField.defaultProps = {
    hideIcon: false,
};
