import Button from 'antd/es/button';
import { notification } from 'antd';
import React from 'react';

/**
 * Показ уведомления о завершении оценки из мобильного приложения.
 */
export const inspectionCompleteNotify = (
    inspectionId: number,
    onClick: (inspectionId: number) => void,
    withSound = true,
) => {
    if (withSound) {
        const audio = new Audio('/assets/complete_notification.wav');
        audio.play().catch((err) => {
            console.log('Ошибка воспроизведения аудио файла нотификации', err);
        });
    }

    const key = `open_${Date.now() + inspectionId}`;
    const btn = (
        <Button
            type="primary"
            onClick={() => {
                if (inspectionId) {
                    onClick(inspectionId);
                    notification.destroy(key);
                }
            }}
        >
            Перейти к оценке
        </Button>
    );

    notification.info({
        key,
        btn,
        message: 'Завершение оценки',
    });
};

/**
 * Показ уведомления о появлении аукционной оценки.
 */
export const inspectionAuctionNotify = (
    inspectionId: number,
    worksheetNumber: string,
    onClick: (inspectionId: number) => void,
) => {
    const key = `open_${Date.now() + inspectionId}`;
    const btn = (
        <Button
            type="link"
            onClick={() => {
                if (inspectionId) {
                    onClick(inspectionId);
                    notification.destroy(key);
                }
            }}
        >
            Перейти
        </Button>
    );
    notification.info({
        key,
        duration: 300,
        message: 'Авто подходит для аукциона',
        description: worksheetNumber ? `№ ${worksheetNumber}` : null,
        btn: btn,
    });
};
