import { LoaderFunction, redirect } from 'react-router-dom';
import { AppRoute } from '@/shared/routing';
import { userSession } from '@/shared/user-session';

export const checkSessionLoader: LoaderFunction = ({ request }) => {
    const isLoggedIn = userSession.isActive;
    const url = new URL(request.url);

    if (!isLoggedIn && AppRoute.isGuarded(url.pathname)) {
        return redirect(AppRoute.Auth);
    }

    if (isLoggedIn && AppRoute.is(AppRoute.Auth, url.pathname)) {
        return redirect(AppRoute.Home);
    }

    return null;
};
