import { Button, Form, Input } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

export interface AuthFormData {
    userName: string;
    password: string;
}

export interface AuthFormProps {
    isLoading: boolean;

    onSubmit(data: AuthFormData): void;
}

export const AuthForm = ({ isLoading, onSubmit }: AuthFormProps) => {
    const requiredRule = { required: true, message: 'Пожалуйста, заполните поле' };

    return (
        <Form
            disabled={isLoading}
            name="authForm"
            onFinish={onSubmit}
            onSubmitCapture={(e) => {
                e.preventDefault();
            }}
        >
            <Form.Item name="userName" rules={[requiredRule]}>
                <Input autoFocus type="text" prefix={<UserOutlined />} placeholder="Логин" />
            </Form.Item>
            <Form.Item name="password" rules={[requiredRule]}>
                <Input type="password" prefix={<LockOutlined />} placeholder="Пароль" />
            </Form.Item>

            <Form.Item>
                <Button loading={isLoading} type="primary" htmlType="submit" className={styles.authFormButton}>
                    Вход
                </Button>
            </Form.Item>
        </Form>
    );
};
