import { ApiError } from '@/shared/types';

/**
 * Форматирует переданное число с учётом текущей локали.
 */
export function toLocalNumber(value: string | number) {
    return Number(value).toLocaleString();
}

/**
 * Выставляет фокус на DOM элементе с указанным id.
 */
export function setFocusOnElement(elementId: string) {
    return (visible: boolean) => {
        if (visible) {
            const timeout = setTimeout(() => {
                document.getElementById(elementId)?.focus();
                clearTimeout(timeout);
            });
        }
    };
}

/**
 * Выставляет ФИО пользователя.
 */
export function setFullName(userData: { lastName?: string; firstName?: string; secondName?: string }) {
    // В поле 'фамилия' записываем полное имя пользователя
    if (userData.lastName) {
        const { lastName, firstName, secondName } = userData;
        userData.lastName = [lastName, firstName, secondName].filter(Boolean).join(' ');
    }
    return userData;
}

/**
 * Сохраняет переданные данные в файл.
 */
export function saveFile(fileData: BlobPart, fileType: string | undefined, filename: string) {
    const blobData = new Blob([fileData], { type: fileType });
    const downloadLink = document.createElement('a');
    const objUrl = URL.createObjectURL(blobData);
    downloadLink.download = filename;
    downloadLink.href = objUrl;
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    URL.revokeObjectURL(objUrl);
    document.body.removeChild(downloadLink);
}

/**
 * Убирает все пробелы из переданного значения.
 */
export function trimWhitespaces(value: string) {
    return (value || '').replace(new RegExp(/\s/, 'g'), '');
}

export function getErrMessage(err: ApiError, defaultMessage = ''): string {
    return typeof err !== 'string' ? err?.message : err || defaultMessage;
}
