import { attach, createStore } from 'effector';
import { createGate } from 'effector-react';
import { atom } from '@/shared/factory';
import { reshape } from 'patronum';
import { NavigateFunction } from 'react-router-dom';
import type { Location } from '@remix-run/router';

export interface RouterGateState {
    location: Location;
    navigate: NavigateFunction;
}

export const navigationModel = atom(() => {
    const RouterGate = createGate<RouterGateState>();

    const $router = createStore<RouterGateState | null>(null, {
        serialize: 'ignore',
    })
        .on(RouterGate.state, (_, state) => state)
        .reset(RouterGate.close);

    const { $query, $path } = reshape({
        source: $router,
        shape: {
            $query: (state) => state?.location?.search,
            $path: (state) => state?.location?.pathname,
        },
    });

    const pushFx = attach({
        source: $router,
        effect: (router, url: string) => router?.navigate(url),
    });

    const replaceFx = attach({
        source: $router,
        effect: (router, url: string) => router?.navigate(url, { replace: true }),
    });

    return {
        RouterGate,
        $router,
        $query,
        $path,
        pushFx,
        replaceFx,
    };
});
