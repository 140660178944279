/**
 * Общие константы приложения.
 */

import React from 'react';

/**
 * Минимальная длина пароля.
 */
export const MIN_PASSWORD_LENGTH = 5;

/**
 * Минимальная длина поля username.
 */
export const MIN_USERNAME_LENGTH = 5;

/**
 * Максимальная длина поля username.
 */
export const MAX_USERNAME_LENGTH = 32;

/**
 * Максимальная длина поля ФИО.
 */
export const MAX_NAME_LENGTH = 64;

/**
 * Миниммальное значение поля "код-сотрудника".
 */
export const MIN_EMPLOYEE_CODE_LENGTH = 5;

/**
 * Максимальная длина поля "код-сотрудника".
 */
export const MAX_EMPLOYEE_CODE_LENGTH = 128;

/**
 * Максимальное значение цены.
 */
export const MAX_COST_VALUE = 2147483647;

/**
 * Символ валюты.
 */
export const CURRENCY_RUB = <span>&#x20BD;</span>;

/**
 * Ограничение дня рождения, не младше 1000 года н.э.
 */
export const UNIX_BIRTHDATE_LIMIT = -30610224000;

export const UNDEFINED_BRAND = 'UNDEFINED_BRAND';

export const UNDEFINED_MODEL = 'UNDEFINED_MODEL';
