import { UserRole } from '@/shared/constants/user-role';
import { COMPLETED } from '@/shared/constants/inspection-status';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

const { RATER } = UserRole;

/**
 * RBAC правила для Руководителя оценщиков - РОВ (Руководитель отдела выкупа).
 */
export const RATER_MANAGER = {
    static: [
        RbacAction.HOME_PAGE_VISIT,
        RbacAction.INSPECTION_LIST,
        RbacAction.MANAGE,
        RbacAction.USER_LIST,
        RbacAction.INSPECTION_COMPLETED_VIEW_RATER,
        RbacAction.SHOW_USER_DIVISION,
        RbacAction.INSPECTION_BID_LIST,
        RbacAction.STATISTIC_VIEW,
        RbacAction.CAR_HISTORY_VISIT,
        RbacAction.INSPECTIONS_SUBSCRIBE,
        RbacAction.AUCTION_NOTIFICATION,
        RbacAction.INSPECTIONS_TODAY_VISIT,
        RbacAction.INSPECTION_VISIT,
        RbacAction.INSPECTIONS_TODAY_MANAGER_MODE,
    ],
    dynamic: {
        [RbacAction.INSPECTION_COMPLETED_VISIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.PROFILE_VISIT]: ({ data }) => {
            return data && (Number(userSession.userId) === Number(data.id) || data.role === RATER);
        },
        [RbacAction.PROFILE_UPDATE]: ({ data }) => {
            return data && (data.role === RATER || Number(data.id) === Number(userSession.userId));
        },
        // [RbacAction.SET_USER_DIVISION]: (userProfile) => {
        //     return userSession.userId !== userProfile?.id;
        // },
    },
};
