/**
 * Максимальное кол-во загружаемых новых оценок.
 */
export const MAX_NEW_INSPECTIONS_LOADED = 100;

/**
 * Максимальное кол-во загружаемых оценок в работе.
 */
export const MAX_WORK_INSPECTIONS_LOADED = 100;

/**
 * Количество элементов для завершённых оценок на странице InspectionsToday.
 */
export const INSPECTIONS_COMPLETED_TODAY_LIST_SIZE = 5;
