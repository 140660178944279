import { AuthCard } from './card';
import { AuthForm } from './form';
import styles from './styles.module.css';
import { useUnit } from 'effector-react';
import { authWidgetModel } from '@/widgets/auth/model';

export const AuthWidget = () => {
    const [formSubmit, $errorMessage, $isPending] = useUnit([
        authWidgetModel.formSubmit,
        authWidgetModel.$errorMessage,
        authWidgetModel.$isPending,
    ]);

    return (
        <div className={styles.authWidget}>
            <AuthCard errorMessage={$errorMessage}>
                <AuthForm isLoading={$isPending} onSubmit={formSubmit} />
            </AuthCard>
        </div>
    );
};
