import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import React, { PropsWithChildren } from 'react';

export interface NavMenuItemProps extends PropsWithChildren {
    to: string;
    className?: string;
}

export const NavMenuItem = ({ to, children, className, ...props }: NavMenuItemProps) => (
    <Menu.Item {...props} className={className}>
        <NavLink to={to} className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'active' : '')}>
            {children}
        </NavLink>
    </Menu.Item>
);
