import { attach, createStore } from 'effector';
import { atom } from '@/shared/factory';

export const AUTOINSPECT_PERSIST_KEY = 'autoinspect';

export const persistModel = atom(() => {
    const $storage = createStore({});

    const pushFx = attach({
        source: $storage,
        effect: (
            _,
            data: {
                key: string;
                value: string;
            },
        ) => {
            const localData = JSON.parse(localStorage.getItem(AUTOINSPECT_PERSIST_KEY));
            localStorage.setItem(
                AUTOINSPECT_PERSIST_KEY,
                JSON.stringify({
                    ...localData,
                    [data.key]: data.value,
                }),
            );
        },
    });

    return {
        pushFx,
    };
});
