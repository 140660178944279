import React from 'react';
import { Tag } from 'antd';
import { DANGER_COLOR, DANGER_TAG_COLOR, OK_COLOR } from '@/shared/constants';
import { getDefectLabelIcon } from '@/shared/components/common/defects/defect-labels/defect-label';
import { ExternalDefectType } from '@/shared/enums';
import { NO_ITEM, hasExternalDefects, mapExternalDefects } from '@/shared/lib/defects.config';

/**
 * Внешние дефекты.
 */
export interface ExternalDefectsFieldProps {
    data?: ExternalDefectType[];
    hideIcon?: boolean;
}

/**
 * Внешние дефекты.
 */
export const ExternalDefectsField = ({ data, hideIcon }: ExternalDefectsFieldProps) => {
    if (!data) return null;

    const isThereDefects = hasExternalDefects(data);
    const color = isThereDefects ? DANGER_COLOR : OK_COLOR;

    return (
        <div className="defect-field-item">
            {!hideIcon && getDefectLabelIcon(isThereDefects, color)}
            <span className="defect-item-title">Внешние дефекты:&nbsp;</span>
            {isThereDefects ? (
                data.map((item) => (
                    <Tag
                        key={item}
                        color={DANGER_TAG_COLOR}
                        style={{
                            marginRight: '2px',
                            fontSize: '11px',
                            lineHeight: '14px',
                        }}
                    >
                        {mapExternalDefects(item)}
                    </Tag>
                ))
            ) : (
                <span className="defect-item-value" style={{ color }}>
                    {NO_ITEM}
                </span>
            )}
        </div>
    );
};

ExternalDefectsField.defaultProps = {
    hideIcon: false,
};
