import { ThemeConfig } from 'antd';

export const appTheme: ThemeConfig = {
    cssVar: { key: 'app' },
    hashed: false,
    token: {
        fontSize: 14,
        borderRadius: 8,
        colorPrimary: '#3431b2',
    },
};
