import React from 'react';
import { Switch, Tag } from 'antd';
import { debounce } from 'lodash/function';
import PropTypes from 'prop-types';
import './style.css';

export interface OffsiteLabelProps {
    checked: boolean;
    handleClick: () => void;
    disabled: boolean;
    loading: boolean;
}

/**
 * Компонент отображения выбора "Выездная" оценка.
 */
export const OffsiteLabel = ({ checked, handleClick, disabled, loading }: OffsiteLabelProps) => {
    return (
        <Tag className="offsite-inspection-container" onClick={disabled ? null : debounce(handleClick, 200)}>
            <span>Выездная</span>
            <Switch
                className="offsite-inspection-switch"
                size="small"
                checked={checked}
                disabled={disabled}
                loading={loading}
            />
        </Tag>
    );
};

OffsiteLabel.propTypes = {
    checked: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};
