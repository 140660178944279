/**
 * Роль пользователя.
 */
export class UserRole {
    /**
     * Виртуальный пользователь 1С "Альфа".
     */
    static ALPHA = 'ALPHA';

    /**
     * Админ.
     */
    static ADMIN = 'ADMIN';

    /**
     * Руководитель дистанционной оценки (РДО). Руководитель прайсеров.
     */
    static APPRAISER_MANAGER = 'APPRAISER_MANAGER';

    /**
     * Эксперт дистанционной оценки (ЭДО). Прайсер.
     */
    static APPRAISER = 'APPRAISER';

    /**
     * Руководитель отдела выкупа (РОВ). Руководитель оценщиков.
     */
    static RATER_MANAGER = 'RATER_MANAGER';

    /**
     * Специалист по выкупу авто (СВА). Оценщик.
     */
    static RATER = 'RATER';

    /**
     * Наблюдатель.
     */
    static WATCHER = 'WATCHER';

    /**
     * Специалист по осмотру авто (СОА).
     */
    static INVITED_RATER = 'INVITED_RATER';

    /**
     * Электрик.
     */
    static ELECTRICIAN = 'ELECTRICIAN';

    /**
     * Механик.
     */
    static MECHANIC = 'MECHANIC';

    /**
     * Список всех доступных ролей.
     */
    static get list(): UserRole[] {
        const {
            ALPHA,
            ADMIN,
            APPRAISER_MANAGER,
            APPRAISER,
            RATER_MANAGER,
            RATER,
            WATCHER,
            INVITED_RATER,
            MECHANIC,
            ELECTRICIAN,
        } = UserRole;

        return [
            new UserRole(ADMIN, 'Администратор', [
                ALPHA,
                ADMIN,
                APPRAISER_MANAGER,
                RATER_MANAGER,
                APPRAISER,
                RATER,
                WATCHER,
                INVITED_RATER,
                MECHANIC,
                ELECTRICIAN,
            ]),
            new UserRole(APPRAISER_MANAGER, 'РДО (Руководитель дистанционной оценки)', [APPRAISER]),
            new UserRole(APPRAISER, 'ЭДО (Эксперт дистанционной оценки)'),
            new UserRole(RATER_MANAGER, 'РОВ (Руководитель отдела выкупа)', [RATER, INVITED_RATER]),
            new UserRole(RATER, 'СВА (Специалист по выкупу авто)'),
            new UserRole(INVITED_RATER, 'СОА (Специалист по осмотру авто)'),
            new UserRole(ALPHA, '1С "Альфа"'),
            new UserRole(WATCHER, 'Наблюдатель'),
            new UserRole(MECHANIC, 'Механик'),
            new UserRole(ELECTRICIAN, 'Электрик'),
        ];
    }

    /**
     * Возвращает label для переданного идентификатора роли.
     */
    static getLabelById(roleId: string): string {
        const role = UserRole.getById(roleId);
        return role ? role.label : 'unknown';
    }

    /**
     * Возвращает объект роли для переданного ID.
     */
    static getById(roleId: string): UserRole {
        return UserRole.list.find((role) => role.id === roleId);
    }

    /**
     * Определяет, что переданный id роли, соответствует админ роли.
     */
    static isAdmin(roleId: string): boolean {
        return roleId === UserRole.ADMIN;
    }

    /**
     * Определяет, что переданный id роли, соответствует РОВ.
     */
    static isRaterManager(roleId: string): boolean {
        return roleId === UserRole.RATER_MANAGER;
    }

    constructor(
        readonly id: string,
        readonly label: string,
        readonly slaves: string[] = [],
    ) {}
}
