import { userSession } from '@/shared/user-session';
import { DynamicParams, RbacAction, rules } from './rules';

export const can = <T = DynamicParams>(action: RbacAction, params?: T) => {
    const permissions = rules[userSession.role];
    if (!permissions) {
        return false;
    }

    const staticPermissions = permissions.static;
    if (staticPermissions?.includes(action)) {
        return true;
    }

    const dynamicPermissions = permissions.dynamic;
    if (dynamicPermissions) {
        const permissionCondition = dynamicPermissions[action];
        return !permissionCondition ? false : permissionCondition(params);
    }

    return false;
};

export const cannot = <T = DynamicParams>(action: RbacAction, params?: T) => !can(action, params);
