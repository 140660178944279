import { atom } from '@/shared/factory';
import { createStore, sample } from 'effector';
import {
    CompletedInspectionToday,
    InspectionTodayItem,
    NewInspectionTodayType,
    TodayInspectionsResult,
    WorkInspectionToday,
} from './types';
import {
    loadCompletedInspectionQuery,
    loadNewInspectionsQuery,
    loadWorkInspectionsQuery,
    moveToWorkInspectionQuery,
} from './api';
import { reshape } from 'patronum';

export const newInspectionsTodayModel = atom(() => {
    const $items = createStore<NewInspectionTodayType[]>([]);
    const $isLoading = loadNewInspectionsQuery.$pending;
    const loadItems = loadNewInspectionsQuery.start;
    const moveToWork = moveToWorkInspectionQuery.start;
    const movedToWorkSuccess = moveToWorkInspectionQuery.finished.success;

    sample({
        clock: loadNewInspectionsQuery.finished.success,
        fn: ({ result }) => result?.list ?? [],
        target: $items,
    });

    sample({
        clock: $items,
        fn: (items) => sortItems(items),
        target: $items,
    });

    sample({
        clock: movedToWorkSuccess,
        target: loadItems,
    });

    return {
        $items,
        $isLoading,
        loadItems,
        moveToWork,
        movedToWorkSuccess,
    };
});

export const workInspectionsTodayModel = atom(() => {
    const $items = createStore<WorkInspectionToday[]>([]);
    const $isLoading = loadWorkInspectionsQuery.$pending;
    const loadItems = loadWorkInspectionsQuery.start;

    sample({
        clock: loadWorkInspectionsQuery.finished.success,
        fn: ({ result }) => result?.list ?? [],
        target: $items,
    });

    sample({
        clock: $items,
        fn: (items) => sortItems(items),
        target: $items,
    });

    sample({
        clock: newInspectionsTodayModel.movedToWorkSuccess,
        target: loadItems,
    });

    return {
        $items,
        $isLoading,
        loadItems,
    };
});

export const completedInspectionsTodayModel = atom(() => {
    const $result = createStore<TodayInspectionsResult<CompletedInspectionToday> | null>(null);
    const $isLoading = loadCompletedInspectionQuery.$pending;
    const loadItems = loadCompletedInspectionQuery.start;

    sample({
        clock: loadCompletedInspectionQuery.finished.success,
        fn: ({ result }) => result,
        target: $result,
    });

    const { $items, $count, $page } = reshape({
        source: $result,
        shape: {
            $items: (state) => state?.list ?? [],
            $count: (state) => state?.count ?? 0,
            $page: (state) => state?.page ?? 1,
        },
    });

    return {
        $items,
        $count,
        $page,
        $isLoading,
        loadItems,
    };
});

function sortItems<T extends InspectionTodayItem>(items: T[]) {
    return items.sort((a, b) => b.percentFill - a.percentFill || a.createdAt - b.createdAt);
}
