import React from 'react';
import { Tag } from 'antd';
import { DANGER_COLOR, DANGER_TAG_COLOR, OK_COLOR } from '@/shared/constants';
import { PedalSwitchDefectType } from '@/shared/enums';
import { getDefectLabelIcon } from '../defect-labels/defect-label';
import { mapPedalSwitchDefects } from '@/shared/lib';

export interface PedalSwitchFieldProps {
    data: PedalSwitchDefectType[];
    hideIcon?: boolean;
}

/**
 * Дефекты педального узла.
 */
export const PedalSwitchField = ({ data, hideIcon }: PedalSwitchFieldProps) => {
    if (!data?.length) return null;

    const hasDefects = data.length && !data.includes(PedalSwitchDefectType.NONE);
    const color = hasDefects ? DANGER_COLOR : OK_COLOR;
    return (
        <div className="defect-field-item">
            {!hideIcon && getDefectLabelIcon(hasDefects, color)}
            <span className="defect-item-title">Дефекты педального узла:&nbsp;</span>
            {hasDefects ? (
                data.map((item) => (
                    <Tag key={item} color={DANGER_TAG_COLOR}>
                        {mapPedalSwitchDefects(item)}
                    </Tag>
                ))
            ) : (
                <span className="defect-item-value" style={{ color }}>
                    Отсутствуют
                </span>
            )}
        </div>
    );
};

PedalSwitchField.defaultProps = {
    hideIcon: false,
};
