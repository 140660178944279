import PropTypes from 'prop-types';
import dayjs from 'dayjs';

export interface UserLastVisitProps {
    date?: number;
    format?: string;
}

/**
 * Компонент отображения даты последнего визита пользователя.
 */
export const UserLastVisit = ({ date, format }: UserLastVisitProps) =>
    !date ? 'не посещал' : dayjs.unix(date).format(format);

UserLastVisit.propTypes = {
    date: PropTypes.number,
    format: PropTypes.string,
};

UserLastVisit.defaultProps = {
    format: 'DD-MM-YYYY HH:mm',
};
