import React from 'react';
import {
    AuditOutlined,
    CarOutlined,
    DashboardOutlined,
    FormOutlined,
    ProjectOutlined,
    SolutionOutlined,
    TeamOutlined,
} from '@ant-design/icons';

import { Menu } from 'antd';
import { can, RbacAction } from '@/shared/rbac';
import { NavMenuItem } from '@/shared/components/common';
import { AppRoute } from '@/shared/routing';

const SubMenu = Menu.SubMenu;

export const AdminMenu = ({ ...props }) => {
    return can(RbacAction.MANAGE) ? (
        <SubMenu
            {...props}
            className="sidebar-sub-menu hidden-element"
            title={
                <span>
                    <DashboardOutlined />
                    <span>Администрирование</span>
                </span>
            }
        >
            {can(RbacAction.USER_LIST) ? (
                <NavMenuItem to={AppRoute.Users} key={AppRoute.Users}>
                    <TeamOutlined />
                    <span>Сотрудники</span>
                </NavMenuItem>
            ) : null}

            {can(RbacAction.CLIENT_MANAGE) ? (
                <NavMenuItem to={AppRoute.Clients} key={AppRoute.Clients}>
                    <SolutionOutlined />
                    <span>Клиенты</span>
                </NavMenuItem>
            ) : null}

            {can(RbacAction.INSPECTION_MANAGE) ? (
                <NavMenuItem to={AppRoute.InspectionsManage} key={AppRoute.InspectionsManage}>
                    <ProjectOutlined />
                    <span>Управление оценками</span>
                </NavMenuItem>
            ) : null}

            {can(RbacAction.SCREENS_EDITOR) ? (
                <NavMenuItem to={AppRoute.ScreensEditor} key={AppRoute.ScreensEditor}>
                    <FormOutlined />
                    <span>Редактор экранов</span>
                </NavMenuItem>
            ) : null}

            {can(RbacAction.CAR_CATEGORY_MANAGE) ? (
                <NavMenuItem to={AppRoute.CategoryManagement} key={AppRoute.CategoryManagement}>
                    <CarOutlined />
                    <span>Категории авто</span>
                </NavMenuItem>
            ) : null}

            {can(RbacAction.AUCTION_MANAGE) ? (
                <NavMenuItem to={AppRoute.AuctionManagement} key={AppRoute.AuctionManagement}>
                    <AuditOutlined />
                    <span>Менеджмент аукциона</span>
                </NavMenuItem>
            ) : null}
        </SubMenu>
    ) : null;
};
