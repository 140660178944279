import { PropsWithChildren } from 'react';
import { Space } from 'antd';
import styles from './styles.module.css';
import Alert from 'antd/es/alert/Alert';

export interface AuthCardProps extends PropsWithChildren {
    errorMessage?: string | null;
}

export const AuthCard = ({ errorMessage, children }: AuthCardProps) => {
    return (
        <div>
            <div className={styles.authLogoWrapper}>
                <img src="/assets/autoinspect_logo.svg" alt="Autoinspect logo" width={116} height={116} />
            </div>
            <div className={styles.authLogoText}>
                Auto<span className={styles.authLogoExtraText}>Inspect</span>
            </div>
            <Space direction="vertical" className={styles.authFormWrapper}>
                {errorMessage ? <Alert message={errorMessage} type="error" showIcon /> : null}
                {children}
            </Space>
        </div>
    );
};
