import { useGate } from 'effector-react';
import { navigationModel } from '@/shared/navigation/model';
import type { Location } from '@remix-run/router';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const useNavigationModel = (effect?: () => void) => {
    const location: Location = useLocation();
    const navigate: NavigateFunction = useNavigate();
    useGate(navigationModel.RouterGate, { location, navigate });

    useEffect(() => effect && effect(), []);
};
