import { atom } from '@/shared/factory';
import { createEvent, createStore, sample } from 'effector';
import { loginQuery } from '@/entities/session';
import { AuthFormData } from './form';

export const authWidgetModel = atom(() => {
    const $isPending = loginQuery.$pending;
    const $errorMessage = createStore<string | null>(null);
    const formSubmit = createEvent<AuthFormData>();

    $errorMessage
        .on(loginQuery.finished.failure, (_, { error }) => {
            return !error.statusCode ? 'Failed to fetch' : error.data?.message;
        })
        .reset(loginQuery.finished.success);

    sample({
        clock: formSubmit,
        target: loginQuery.start,
    });

    return {
        $errorMessage,
        $isPending,
        formSubmit,
    };
});
