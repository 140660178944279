import { Divisions, UserRole, UserType } from '@/shared/constants';
import { UserData } from '@/shared/types';

export interface UserAuthInfo {
    userId: number;
    lastAgent: string;
}

export type AuthData = Record<keyof SessionData, string>;

/**
 * Сессионные данные аутентифицированного пользователя.
 */
export class SessionData implements UserData {
    static from(data: AuthData): SessionData {
        const sessionData = new SessionData();
        sessionData.id = Number(data.id);
        sessionData.userName = data.userName;
        sessionData.code = data.code;
        sessionData.email = data.email;
        sessionData.phone = data.phone;
        sessionData.firstName = data.firstName;
        sessionData.secondName = data.secondName;
        sessionData.lastName = data.lastName;
        sessionData.created = Number(data.created);
        sessionData.lastVisit = Number(data.lastVisit);
        sessionData.division = Divisions.getById(data.division);
        sessionData.role = UserRole.getById(data.role);
        sessionData.type = UserType.getById(data.type);
        sessionData.isPartner = Boolean(data.isPartner);
        sessionData.authToken = data.authToken;
        return sessionData;
    }

    static fromJson(jsonData: string): SessionData {
        // TODO добавить валидацию
        return SessionData.from(JSON.parse(jsonData) as AuthData);
    }

    id: number;
    userName: string;
    code: string;
    email: string;
    phone: string;
    firstName: string;
    secondName: string;
    lastName: string;
    created: number;
    lastVisit: number;
    isPartner: boolean;
    authToken: string;
    division: Divisions;
    role: UserRole;
    type: UserType;
    authInfo?: UserAuthInfo;

    toJson(): string {
        return JSON.stringify({
            ...this,
            role: this.role.id,
            division: this.division.id,
            type: this.type.id,
        });
    }
}
