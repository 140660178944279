import React from 'react';
import ReactDOM from 'react-dom';
import { AppRouter } from './router';
import { AntdConfigProvider } from './providers';
import 'react-custom-scroll/dist/customScroll.css';
import '@/shared/providers/sentry';
import './styles/index.css';
import './styles/inspections.css';
import './styles/ant-reset.css';

ReactDOM.render(
    <AntdConfigProvider>
        <AppRouter />
    </AntdConfigProvider>,
    document.getElementById('root'),
);
