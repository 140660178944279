import React from 'react';
import { DANGER_COLOR, OK_COLOR } from '@/shared/constants';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

/**
 * Требуется окрас.
 */
export const TRUE_REPAINT_STATE = {
    title: 'Да',
    color: DANGER_COLOR,
    icon: ExclamationCircleOutlined,
};

/**
 * Окрас не требуется.
 */
export const FALSE_REPAINT_STATE = {
    title: 'Нет',
    color: OK_COLOR,
    icon: CheckCircleOutlined,
};

export interface NeedRepaintFieldProps {
    data?: boolean;
    hideIcon?: boolean;
}

/**
 * Требуется окрас.
 */
export const NeedRepaintField = ({ data, hideIcon }: NeedRepaintFieldProps) => {
    if (data === undefined || data === null) return null;

    const defect = data === true ? TRUE_REPAINT_STATE : FALSE_REPAINT_STATE;
    return (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Требуется окрас:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    );
};

NeedRepaintField.defaultProps = {
    hideIcon: false,
};
