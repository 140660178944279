import React, { useRef } from 'react';
import {
    BarChartOutlined,
    FileDoneOutlined,
    FireOutlined,
    HomeOutlined,
    LineChartOutlined,
    PieChartOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { Badge, Layout, Menu } from 'antd';
import { AdminMenu } from './admin-menu';
import { NavMenuItem } from '@/shared/components/common';
import { can, RbacAction } from '@/shared/rbac';
import { useUnit } from 'effector-react';
import { newInspectionsTodayModel } from '@/entities/inspection';
import { AppRoute } from '@/shared/routing';
import { navigationModel } from '@/shared/navigation';
import './style.css';

const { Sider } = Layout;
const { SubMenu } = Menu;

const SIDEBAR_WIDTH = 254;
const WINDOW_ON_COLLAPSE_WIDTH = 998;

export interface SidebarProps {
    onCollapse: () => void;
    collapsed: boolean;
}

export const Sidebar = ({ onCollapse, collapsed }: SidebarProps) => {
    const [newInspections, path] = useUnit([newInspectionsTodayModel.$items, navigationModel.$path]);
    const menuRef = useRef();
    const onSelectMenu = () => {
        if (window.innerWidth <= WINDOW_ON_COLLAPSE_WIDTH) {
            onCollapse();
        }
    };

    return (
        <Sider
            width={SIDEBAR_WIDTH}
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            className={collapsed ? 'app-sidebar collapsed' : 'app-sidebar'}
        >
            {collapsed && <div className="covered" />}
            <div className="logo-wrapper">
                <img className="logo-img" alt="Autoinspect Logo" src="/assets/autoinspect_logo.svg" />
                <div className={collapsed ? 'logo-text collapsed' : 'logo-text'}>
                    Auto<span className="extra-logo-text">Inspect</span>
                </div>
            </div>
            <Menu mode="inline" selectedKeys={[path]} onSelect={onSelectMenu} className="sidebar-menu" ref={menuRef}>
                <NavMenuItem key={AppRoute.Home} to={AppRoute.Home}>
                    <HomeOutlined />
                    <span>Home</span>
                </NavMenuItem>

                {can(RbacAction.INSPECTIONS_TODAY_VISIT) && (
                    <NavMenuItem key={AppRoute.InspectionsToday} to={AppRoute.InspectionsToday}>
                        <FireOutlined />
                        <span>Оценки сегодня</span>
                        <Badge style={{ marginLeft: '8px' }} count={newInspections.length} />
                    </NavMenuItem>
                )}

                <NavMenuItem
                    className="hidden-element"
                    key={AppRoute.InspectionsCompleted}
                    to={AppRoute.InspectionsCompleted}
                >
                    <FileDoneOutlined />
                    <span>Завершённые оценки</span>
                </NavMenuItem>

                {can(RbacAction.INSPECTION_BID_LIST) && (
                    <NavMenuItem key={AppRoute.InspectionBids} to={AppRoute.InspectionBids}>
                        <UnorderedListOutlined />
                        <span>Заявки</span>
                    </NavMenuItem>
                )}

                {can(RbacAction.STATISTIC_VIEW) && (
                    <SubMenu
                        className="hidden-element"
                        key="submenu-statistic"
                        title={
                            <span>
                                <PieChartOutlined />
                                <span>Статистика</span>
                            </span>
                        }
                    >
                        <NavMenuItem key={AppRoute.CommonStatistic} to={AppRoute.CommonStatistic}>
                            <LineChartOutlined />
                            <span>Общая статистика</span>
                        </NavMenuItem>

                        <NavMenuItem key={AppRoute.InspectionStatistic} to={AppRoute.InspectionStatistic}>
                            <BarChartOutlined />
                            <span>Статистика по оценкам</span>
                        </NavMenuItem>
                    </SubMenu>
                )}

                <AdminMenu menu={menuRef} key="admin-menu" />
            </Menu>
        </Sider>
    );
};
