export enum CarDataEntityName {
    SelfDiagnostic = 'selfDiagnostic',
    TestDrive = 'testDrive',
    Advantage = 'advantage',
    Documents = 'documents',
}

export enum DefectsEntityName {
    FrontSide = 'frontSide',
    RightSide = 'rightSide',
    LeftSide = 'leftSide',
    BackSide = 'backSide',
    Roof = 'roof',
    Floor = 'floor',
}
