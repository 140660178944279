import React from 'react';
import { DANGER_COLOR, OK_COLOR } from '@/shared/constants';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { BindingDefectType } from '@/shared/enums';
import { mapBindingsDefects } from '@/shared/lib/defects.config';

const BINDINGS_DEFECTS = {
    /**
     * Норма.
     */
    [BindingDefectType.NONE]: {
        title: mapBindingsDefects(BindingDefectType.NONE),
        color: OK_COLOR,
        icon: CheckCircleOutlined,
    },
    /**
     * Демонтировалось.
     */
    [BindingDefectType.DEMOUNT]: {
        title: mapBindingsDefects(BindingDefectType.DEMOUNT),
        color: DANGER_COLOR,
        icon: ExclamationCircleOutlined,
    },
};

export interface BindingsFieldProps {
    data: BindingDefectType;
    hideIcon?: boolean;
}

/**
 * Дефекты креплений.
 */
export const BindingsField = ({ data, hideIcon }: BindingsFieldProps) => {
    if (!data) return null;

    const defect = BINDINGS_DEFECTS[data];
    return defect ? (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Дефекты крепления:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    ) : null;
};
