/**
 * Внешние дефекты.
 */
export enum ExternalDefectType {
    /**
     * Вмятина.
     */
    DENT = 'DENT',
    /**
     * Скол.
     */
    CHIP = 'CHIP',
    /**
     * Много сколов.
     */
    MANY_CHIPS = 'MANY_CHIPS',
    /**
     * Дыра.
     */
    HOLE = 'HOLE',
    /**
     * Отсутствует элемент.
     */
    VOID = 'VOID',
    /**
     * Трещина.
     */
    CRACK = 'CRACK',
    /**
     * Сломано.
     */
    BROKEN = 'BROKEN',
    /**
     * Царапина.
     */
    SCRATCH = 'SCRATCH',
    /**
     * Разбито.
     */
    CRASHED = 'CRASHED',
    /**
     * Большой зазор.
     */
    LARGE_GAP = 'LARGE_GAP',
    /**
     * Коррозия.
     */
    CORROSION = 'CORROSION',
    /**
     * Потёртость.
     */
    ATTRITION = 'ATTRITION',
    /**
     * Нет дефектов.
     */
    NONE = 'NONE',
    /**
     * Пусто.
     */
    EMPTY = 'EMPTY',
}
