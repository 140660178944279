import { COMPLETED } from '@/shared/constants/inspection-status';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

/**
 * RBAC правила для Администратора.
 */
export const ADMIN = {
    static: [
        RbacAction.HOME_PAGE_VISIT,
        RbacAction.PROFILE_VISIT,
        RbacAction.CLIENT_LIST,
        RbacAction.CLIENT_VISIT,
        RbacAction.CLIENT_CREATE,
        RbacAction.CLIENT_MANAGE,
        RbacAction.INSPECTION_LIST,
        RbacAction.ACCOUNT_UPDATE,
        RbacAction.PROFILE_UPDATE,
        RbacAction.PASSWORD_UPDATE,
        RbacAction.MANAGE,
        RbacAction.INSPECTION_MANAGE,
        RbacAction.INSPECTION_MANAGE_EDIT,
        RbacAction.USER_LIST,
        RbacAction.USER_CREATE,
        RbacAction.USER_CREATE_PARTNER,
        RbacAction.SET_USER_DIVISION,
        RbacAction.SHOW_USER_DIVISION,
        RbacAction.INSPECTIONS_TODAY_VISIT,
        RbacAction.INSPECTIONS_TODAY_MANAGER_MODE,
        RbacAction.INSPECTION_VISIT,
        RbacAction.INSPECTION_MANAGE_VIEW,
        RbacAction.INSPECTION_COMPLETED_VIEW_DIVISIONS,
        RbacAction.INSPECTION_COMPLETED_VIEW_RATER,
        RbacAction.INSPECTION_COMPLETED_VIEW_APPRAISER,
        RbacAction.INSPECTION_INSPECTION_INFO_UPDATE,
        RbacAction.INSPECTIONS_SUBSCRIBE,
        RbacAction.STATISTIC_VIEW,
        RbacAction.SCREENS_EDITOR,
        RbacAction.CAR_CATEGORY_MANAGE,
        RbacAction.CAR_CATEGORY_VISIT,
        RbacAction.INSPECTION_BID_LIST,
        RbacAction.CAR_HISTORY_VISIT,
        RbacAction.CAR_HISTORY_BUILD,
        RbacAction.AUCTION_MANAGE,
        RbacAction.AUCTION_NOTIFICATION,
    ],
    dynamic: {
        [RbacAction.USER_REMOVE]: ({ data }) => {
            return userSession.userId !== data.id;
        },
        [RbacAction.INSPECTION_COMPLETED_VISIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.INSPECTION_DOCUMENTS_EDIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.INSPECTION_CAR_EDIT]: (status) => {
            return status === COMPLETED;
        },
    },
};
