import {
    addListener,
    MOVE_INSPECTION_TO_NEW,
    MOVE_INSPECTION_TO_WORK,
    SOCKET_BROADCAST,
    SocketInstance,
    UPDATE_NEW_INSPECTION,
    UPDATE_WORK_INSPECTION,
} from '@/shared/api';
import { atom } from '@/shared/factory';
import { createEffect, createEvent, sample } from 'effector';
import { InspectionTodayRealtimeInfo } from './types';
import { can, RbacAction } from '@/shared/rbac';
import { navigationModel } from '@/shared/navigation';
import { AppRoute } from '@/shared/routing';
import { newInspectionsTodayModel, workInspectionsTodayModel, WorkInspectionToday } from '@/entities/inspection';
import { userSession } from '@/shared/user-session';
import { reducerForNewInspectionsUpdate, reducerForWorkInspectionsUpdate } from './helpers';

const SUPPORTED_EVENTS = [
    MOVE_INSPECTION_TO_NEW,
    MOVE_INSPECTION_TO_WORK,
    UPDATE_NEW_INSPECTION,
    UPDATE_WORK_INSPECTION,
];

export const inspectionsTodayRealtimeModel = atom(() => {
    const init = createEvent<SocketInstance>();
    const handleBroadcast = createEvent<InspectionTodayRealtimeInfo>();
    const updateData = createEvent<InspectionTodayRealtimeInfo>();

    const initSubscriptionsFx = createEffect((socket: SocketInstance) => {
        addListener(socket, SOCKET_BROADCAST, (event: string, data: InspectionTodayRealtimeInfo['data']) =>
            handleBroadcast({ event, data }),
        );
    });

    sample({
        clock: init,
        filter: () => can(RbacAction.INSPECTIONS_SUBSCRIBE),
        target: initSubscriptionsFx,
    });

    sample({
        clock: handleBroadcast,
        source: navigationModel.$path,
        filter: (path, { event }) => {
            return SUPPORTED_EVENTS.includes(event) && AppRoute.match(AppRoute.InspectionsToday, path);
        },
        fn: (_, data) => data,
        target: updateData,
    });

    sample({
        clock: updateData,
        source: newInspectionsTodayModel.$items,
        filter: (_, { event }) => event === UPDATE_NEW_INSPECTION,
        fn: reducerForNewInspectionsUpdate,
        target: newInspectionsTodayModel.$items,
    });

    sample({
        clock: updateData,
        source: workInspectionsTodayModel.$items,
        filter: (_, { event }) => event === UPDATE_WORK_INSPECTION,
        fn: reducerForWorkInspectionsUpdate,
        target: workInspectionsTodayModel.$items,
    });

    sample({
        clock: updateData,
        filter: ({ event }) => event === MOVE_INSPECTION_TO_NEW,
        target: newInspectionsTodayModel.loadItems,
    });

    sample({
        clock: updateData,
        filter: ({ event, data }) => {
            return (
                event === MOVE_INSPECTION_TO_WORK && (data as WorkInspectionToday).appraiserId !== userSession.userId
            );
        },
        target: newInspectionsTodayModel.loadItems,
    });

    return {
        init,
    };
});
