import {
    BindingDefectType,
    ErasedWheelDefectType,
    ExternalDefectType,
    PaintedElementDefectType,
    PedalSwitchDefectType,
    SealantDefectType,
} from '@/shared/enums';

/** Внешние дефекты */

export const EXTERNAL_DEFECTS = {
    [ExternalDefectType.NONE]: 'Нет',
    [ExternalDefectType.DENT]: 'Вмятина',
    [ExternalDefectType.CHIP]: 'Скол',
    [ExternalDefectType.HOLE]: 'Дыра',
    [ExternalDefectType.VOID]: 'Отсутствует элемент',
    [ExternalDefectType.CRACK]: 'Трещина',
    [ExternalDefectType.BROKEN]: 'Сломано',
    [ExternalDefectType.SCRATCH]: 'Царапина',
    [ExternalDefectType.CRASHED]: 'Разбито',
    [ExternalDefectType.LARGE_GAP]: 'Большой зазор',
    [ExternalDefectType.CORROSION]: 'Коррозия',
    [ExternalDefectType.ATTRITION]: 'Потёртость',
    [ExternalDefectType.MANY_CHIPS]: 'Много сколов',
};

export const hasExternalDefects = (data: ExternalDefectType[]) =>
    data.length && !data.includes(ExternalDefectType.NONE);

export const mapExternalDefects = (item: ExternalDefectType) => EXTERNAL_DEFECTS[item];

export const NO_ITEM = 'Отсутствуют';

export const inlineExternalDefects = (data: ExternalDefectType[]) => {
    if (!data.length) {
        return NO_ITEM;
    }

    return data.map(mapExternalDefects).join(', ');
};

/** Износ колеса */

export const ERASED_DEFECTS = {
    /**
     * Высокий.
     */
    [ErasedWheelDefectType.HIGHT]: 'Высокий',
    /**
     * Средний.
     */
    [ErasedWheelDefectType.MEDIUM]: 'Средний',
    /**
     * Малый.
     */
    [ErasedWheelDefectType.LOW]: 'Малый',
};

export const mapErasedWheelDefects = (value: string) => ERASED_DEFECTS[value];

/**
 * Дефекты герметика.
 */
export const SEALANT_DEFECTS = {
    [SealantDefectType.NONE]: 'Герметик отсутствует',
    [SealantDefectType.FACTORY]: 'Заводской',
    [SealantDefectType.NOT_FACTORY]: 'Не заводской',
};

export const mapSealantDefects = (value: string) => SEALANT_DEFECTS[value];

/**
 * Дефекты креплений
 */
export const BINDINGS_DEFECTS = {
    [BindingDefectType.NONE]: 'Отсутствуют',
    [BindingDefectType.DEMOUNT]: 'Демонтировалось',
};

export const mapBindingsDefects = (value: string) => BINDINGS_DEFECTS[value];

/**
 * Дефекты окрашенного элемента.
 */
export const PAINTED_ELEMENT_DEFECTS = {
    /**
     * Отсутствует.
     */
    [PaintedElementDefectType.NONE]: 'Отсутствует',
    /**
     * Шпаклёвка.
     */
    [PaintedElementDefectType.PUTTY]: 'Шпаклёвка',
    /**
     * Некачественный окрас.
     */
    [PaintedElementDefectType.DEFECTIVE_COLOR]: 'Некачественный окрас',
    /**
     * Была замена детали.
     */
    [PaintedElementDefectType.PART_REPLACEMENT]: 'Была замена детали',
    /**
     * Косметический ремонт.
     */
    [PaintedElementDefectType.COSMETIC_REPAIR]: 'Косметический ремонт',
};

export const mapPaintedElementDefects = (value: string) => PAINTED_ELEMENT_DEFECTS[value];

export const inlinePaintedElementDefects = (data: PaintedElementDefectType[]) => {
    if (!data.length) {
        return NO_ITEM;
    }

    return data.map(mapPaintedElementDefects).join(', ');
};

/**
 * Дефекты педального узла
 */
export const PEDAL_SWITCH_DEFECTS = {
    [PedalSwitchDefectType.NONE]: 'Нет',
    [PedalSwitchDefectType.HOLE]: 'Дыра',
    [PedalSwitchDefectType.VOID]: 'Отсутствует элемент',
    [PedalSwitchDefectType.ATTRITION]: 'Потёртость',
};

export const mapPedalSwitchDefects = (value: string) => PEDAL_SWITCH_DEFECTS[value];

export const inlinePedalSwitchDefects = (data: PedalSwitchDefectType[]) => {
    if (!data.length) {
        return NO_ITEM;
    }

    return data.map(mapPedalSwitchDefects).join(', ');
};
