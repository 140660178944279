import * as Sentry from '@sentry/react';

Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: import.meta.env.VITE_APP_VERSION,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: import.meta.env.VITE_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/(.*)autoinspect\.borauto.ru$/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});
