import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Sidebar } from './sidebar';
import { Header } from './header';
import { Footer } from './footer';
import { Outlet } from 'react-router-dom';
import { useNavigationModel } from '@/shared/navigation';
import { useUnit } from 'effector-react';
import { realtimeModel } from '@/features/realtime';
import './style.css';

const defaultStyles = {
    defaultStyle: 'content-wrapper',
    collapsedStyle: 'content-wrapper collapsed',
};

export const Workspace = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [initRealtime, disposeRealtime] = useUnit([realtimeModel.init, realtimeModel.dispose]);

    useNavigationModel();

    useEffect(() => {
        initRealtime();
        return () => disposeRealtime();
    }, []);

    const onCollapse = () => setCollapsed(!collapsed);

    const { defaultStyle, collapsedStyle } = defaultStyles;
    return (
        <Layout hasSider className="workspace">
            <Sidebar collapsed={collapsed} onCollapse={onCollapse} />
            <Layout className={collapsed ? collapsedStyle : defaultStyle}>
                <div onClick={onCollapse} className={collapsed ? '' : 'cover'} />
                <Header collapsed={collapsed} onCollapse={onCollapse} />
                <Layout.Content className="content">
                    <Outlet />
                </Layout.Content>
                <Footer />
            </Layout>
        </Layout>
    );
};
