import React from 'react';
import { Button, Checkbox, Col, Row } from 'antd';
import PropTypes from 'prop-types';

const styles = {
    padding: '8px',
    borderRadius: '6px',
    background: '#fff',
    position: 'relative',
    width: '300px',
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: '0 1px 6px rgba(0, 0, 0, .2)',
} as React.CSSProperties;

export interface MultipleFilterItem {
    value: string;
    text: string;
}

export interface CheckboxGroupFilterProps {
    id: string;
    filters: MultipleFilterItem[];
    setSelectedKeys: (selectedKeys: string[]) => void;
    selectedKeys: string[];
    confirm: () => void;
    close: () => void;
    clearFilters: () => void;
    prefixCls?: string;
    width?: number;
}

/**
 * Компонент фильтра c множественным выбором.
 */
export const CheckboxGroupFilter = (props: CheckboxGroupFilterProps) => {
    const { filters, confirm, clearFilters, setSelectedKeys, close, prefixCls, width, id } = props;

    const onReset = () => {
        clearFilters();
        confirm();
    };

    return (
        <div id={id} className={prefixCls} style={{ ...styles, width }}>
            <Row gutter={8}>
                <Checkbox.Group onChange={setSelectedKeys}>
                    {filters.map(({ text, value }) => (
                        <Col style={{ padding: 5 }} key={value} span={24}>
                            <Checkbox value={value}>{text}</Checkbox>
                        </Col>
                    ))}
                </Checkbox.Group>
                <Col
                    span={24}
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-around',
                        padding: '5px',
                        background: '#fff',
                        position: 'sticky',
                        bottom: -8,
                        borderTop: '1px solid #f0f0f0',
                    }}
                >
                    <Button type="default" size="small" style={{ marginRight: '8px' }} onClick={onReset}>
                        Сброс
                    </Button>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            confirm();
                            close();
                        }}
                    >
                        OK
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

CheckboxGroupFilter.propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    clearFilters: PropTypes.func.isRequired,
    setSelectedKeys: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    selectedKeys: PropTypes.array,
    confirm: PropTypes.func,
};
