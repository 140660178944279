/**
 * Канал оценки.
 */
export class InspectionChannel {
    /**
     * Канал не указан.
     */
    static Unknown = 'unknown';

    /**
     * Оценки из чата.
     */
    static Chat = 'chat';

    /**
     * Оценки сделанные в приложении зарегистрированными физ.лицами.
     */
    static Client = 'client';

    /**
     * Оценки сделанные сотрудниками в АИ с Ролью СВА и СОА. (Отдел Выкупа Авто с Пробегом).
     */
    static Praising = 'praising';

    static defaultValue = new InspectionChannel('n/a', InspectionChannel.Unknown, '#5d6060');

    static all = [
        new InspectionChannel('Чат', InspectionChannel.Chat, '#b472e5'),
        new InspectionChannel('Клиент', InspectionChannel.Client, '#79c14f'),
        new InspectionChannel('ОВ АсП ', InspectionChannel.Praising, '#a9a7a7'),
    ];

    static getByKey(key: string, defaultValue?: string) {
        return InspectionChannel.all.find((channel) => channel.key === key) ?? InspectionChannel.defaultValue;
    }

    name: string;
    key: string;
    color: string;

    constructor(name: string = InspectionChannel.Unknown, key: string, color = '#5d6060') {
        this.name = name;
        this.key = key;
        this.color = color;
    }
}
