export enum RbacAction {
    HOME_PAGE_VISIT = 'home-page:visit',
    PROFILE_VISIT = 'profile:visit',
    CLIENT_LIST = 'client:list',
    CLIENT_VISIT = 'client:visit',
    CLIENT_CREATE = 'client:create',
    CLIENT_MANAGE = 'client:manage',
    INSPECTION_LIST = 'inspection:list',
    ACCOUNT_UPDATE = 'account:update',
    PROFILE_UPDATE = 'profile:update',
    PASSWORD_UPDATE = 'password:update',
    MANAGE = 'manage',
    INSPECTION_MANAGE = 'inspection:manage',
    INSPECTION_MANAGE_EDIT = 'inspection:manage:edit',
    USER_LIST = 'user:list',
    USER_CREATE = 'user:create',
    USER_CREATE_PARTNER = 'user:create:partner',
    SET_USER_DIVISION = 'set-user-division',
    SHOW_USER_DIVISION = 'show-user-division',
    INSPECTIONS_TODAY_VISIT = 'inspections-today:visit',
    INSPECTIONS_TODAY_MANAGER_MODE = 'inspections-today:manager-mode',
    INSPECTION_VISIT = 'inspection:visit',
    INSPECTION_MANAGE_VIEW = 'inspection:manage:view',
    INSPECTION_COMPLETED_VIEW_DIVISIONS = 'inspection:completed:view:divisions',
    INSPECTION_COMPLETED_VIEW_RATER = 'inspection:completed:view:rater',
    INSPECTION_COMPLETED_VIEW_APPRAISER = 'inspection:completed:view:appraiser',
    INSPECTION_INSPECTION_INFO_UPDATE = 'inspection:inspection-info:update',
    INSPECTIONS_SUBSCRIBE = 'inspections:subscribe',
    STATISTIC_VIEW = 'statistic:view',
    SCREENS_EDITOR = 'screens-editor',
    CAR_CATEGORY_MANAGE = 'car-category:manage',
    CAR_CATEGORY_VISIT = 'car-category:visit',
    INSPECTION_BID_LIST = 'inspection-bid:list',
    CAR_HISTORY_VISIT = 'car-history:visit',
    CAR_HISTORY_BUILD = 'car-history:build',
    AUCTION_MANAGE = 'auction:manage',
    AUCTION_NOTIFICATION = 'auction:notification',
    USER_REMOVE = 'user:remove',
    INSPECTION_COMPLETED_VISIT = 'inspection:completed:visit',
    INSPECTION_DOCUMENTS_EDIT = 'inspection:documents:edit',
    INSPECTION_CAR_EDIT = 'inspection:car:edit',
}
