/**
 * Филиалы (подразделения) компании.
 */
export class Divisions {
    /**
     * СКС.
     */
    static SKS = 'ЦБ000006';

    /**
     * Остужева.
     */
    static OSTUZHEVA = 'ЦБ000009';

    /**
     * 9 Января.
     */
    static NINE_JANUARY = 'ЦБ000003';

    /**
     * Тамбов.
     */
    static TAMBOV = 'ЦБ000023';

    /**
     * Липецк.
     */
    static LIPETSK = 'ЦБ000084';

    /**
     * Борисоглебск.
     */
    static BORISOGLEBSK = 'ЦБ000002';

    /**
     * РусьАвто.
     */
    static RUS_AUTO = 'ЦБ000038';

    /**
     * Ставрополь.
     */
    static STAVROPOL = 'ЦБ000141';

    /**
     * "Цифровой дилерский центр" (ЦДЦ).
     */
    static CDC = 'ЦБ000176';

    /**
     * ЦДЦ чат.
     */
    static CDC_CHAT = 'CDC_CHAT';

    /**
     * Ростов Авингрупп.
     */
    static ROSTOV_AVINGROUP = '00-БА0У01';

    /**
     * Краснодар.
     */
    static KRASNODAR = 'РАДТЮ0001';

    /**
     * Виртуальный филиал "Партнёра".
     */
    static PARTNER = 'PARTNER';

    /**
     * Виртуальный филиал "Клиента".
     */
    static CLIENT = 'CLIENT';

    /**
     * Аксай.
     */
    static AKSAY = '00-ПАПУ17';

    /**
     * Список всех филиалов.
     */
    static get list() {
        const {
            SKS,
            OSTUZHEVA,
            BORISOGLEBSK,
            TAMBOV,
            LIPETSK,
            NINE_JANUARY,
            STAVROPOL,
            RUS_AUTO,
            CDC,
            CDC_CHAT,
            ROSTOV_AVINGROUP,
            KRASNODAR,
            PARTNER,
            CLIENT,
            AKSAY,
        } = Divisions;

        return [
            new Divisions(SKS, 'СКС', 'г. Воронеж, ул. Независимости, д.84а', '+7 (473) 211-56-23'),
            new Divisions(OSTUZHEVA, 'Остужева', 'г. Воронеж, ул. Остужева, д.41', '+7 (473) 212-06-78'),
            new Divisions(
                BORISOGLEBSK,
                'Борисоглебск',
                'г. Борисоглебск, ул. Матросовская, д. 127',
                '+7 (473) 212-06-08',
            ),
            new Divisions(TAMBOV, 'Тамбов', 'г. Тамбов, ул. Урожайная, 2В', '+7 (475) 250-37-27'),
            new Divisions(LIPETSK, 'Липецк', 'г. Липецк, ул. Краснозаводская, стр. 2в', '+7 (4742) 54-56-17'),
            new Divisions(NINE_JANUARY, '9 Января', 'г. Воронеж, ул. 9 Января, д. 221д', '+7 (473) 212-06-89'),
            new Divisions(RUS_AUTO, 'РусьАвто', 'г. Ставрополь, ул. 6-я Промышленная, 2', '+7 (865) 297-99-65'),
            new Divisions(STAVROPOL, 'Ставрополь', 'г. Ставрополь, пр-кт Кулакова, 22 Г', '+7 (865) 297-99-54'),
            new Divisions(CDC, 'ЦДЦ', '', ''),
            new Divisions(CDC_CHAT, 'ЦДЦ чат', '', ''),
            new Divisions(
                ROSTOV_AVINGROUP,
                'Ростов Авингрупп',
                'Боравто, г. Ростов-на-Дону, ул. Вавилова, 74А',
                '+7 (800) 505-03-76',
            ),
            new Divisions(KRASNODAR, 'Краснодар', '', ''),
            new Divisions(PARTNER, 'Партнёр', '', ''),
            new Divisions(CLIENT, 'Клиент', '', ''),
            new Divisions(AKSAY, 'Аксай', '', ''),
        ];
    }

    static getLabelById(divisionId: string): string {
        const division = Divisions.getById(divisionId);
        return division ? division.label : 'unknown';
    }

    static getById(divisionId: string): Divisions {
        const normalizedDivisionId = String(divisionId).trim();
        return Divisions.list.find((division) => division.id === normalizedDivisionId);
    }

    static isPartner(divisionId: string): boolean {
        return divisionId === Divisions.PARTNER;
    }

    static isClient(divisionId: string): boolean {
        return divisionId === Divisions.CLIENT;
    }

    getFullAddress() {
        if (!this.address) {
            return '';
        }

        return `Боравто, ${this.address}, ${this.phoneNumber}`;
    }

    constructor(
        readonly id: string,
        readonly label: string,
        readonly address: string,
        readonly phoneNumber: string,
    ) {}
}
