import { AuthWidget } from '@/widgets/auth';
import { AuthLayout } from '@/app/layouts';

export function AuthPage() {
    return (
        <AuthLayout>
            <AuthWidget />
        </AuthLayout>
    );
}
