import { atom } from '@/shared/factory';
import { createEffect, createEvent, sample } from 'effector';
import { can, RbacAction } from '@/shared/rbac';
import {
    addListener,
    addListenerOnce,
    SOCKET_BROADCAST,
    SocketInstance,
    UPDATE_CAR_DATA,
    UPDATE_DEFECTS,
    UPDATE_INSPECTION,
    UPDATE_WORK_INSPECTION,
} from '@/shared/api';
import { CarDataRealtimeInfo, DefectsRealtimeInfo, InspectionRealtimeInfo, UpdateWorkInspectionEvent } from './types';
import { inspectionModel, WorkInspectionToday } from '@/entities/inspection';
import { filterByInspection, reducerForCarDataUpdate, reducerForDefectsUpdate } from './helpers';

export const inspectionViewRealtimeModel = atom(() => {
    const init = createEvent<SocketInstance>();
    const updateInfo = createEvent<InspectionRealtimeInfo>();
    const updateCarData = createEvent<CarDataRealtimeInfo>();
    const updateDefects = createEvent<DefectsRealtimeInfo>();
    const handleBroadcast = createEvent<UpdateWorkInspectionEvent>();
    const updatePercentFill = createEvent<WorkInspectionToday>();

    const initSubscriptionsFx = createEffect((socket: SocketInstance) => {
        addListenerOnce(socket, UPDATE_CAR_DATA, updateCarData);
        addListenerOnce(socket, UPDATE_DEFECTS, updateDefects);
        addListenerOnce(socket, UPDATE_INSPECTION, updateInfo);
        addListener(socket, SOCKET_BROADCAST, (event: string, data: WorkInspectionToday) =>
            handleBroadcast({ event, data }),
        );
    });

    sample({
        clock: init,
        filter: () => can(RbacAction.INSPECTIONS_SUBSCRIBE),
        target: initSubscriptionsFx,
    });

    sample({
        clock: updateInfo,
        source: inspectionModel.$inspection,
        filter: filterByInspection,
        fn: (inspection, { data }) => {
            return { ...inspection, raterStatus: data.status, summary: data.summary };
        },
        target: inspectionModel.$inspection,
    });

    sample({
        clock: updateCarData,
        source: inspectionModel.$inspection,
        filter: filterByInspection,
        fn: reducerForCarDataUpdate,
        target: inspectionModel.$inspection,
    });

    sample({
        clock: updateDefects,
        source: inspectionModel.$defects,
        filter: filterByInspection,
        fn: reducerForDefectsUpdate,
        target: inspectionModel.$defects,
    });

    sample({
        clock: handleBroadcast,
        filter: ({ event }) => event === UPDATE_WORK_INSPECTION,
        fn: ({ data }) => data,
        target: updatePercentFill,
    });

    sample({
        clock: updatePercentFill,
        source: inspectionModel.$inspection,
        filter: (inspection, { id }) => filterByInspection(inspection, { inspectionId: id }),
        fn: (inspection, { percentFill }) => ({ ...inspection, percentFill }),
        target: inspectionModel.$inspection,
    });

    return {
        init,
    };
});
