import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import React from 'react';
import './style.css';

const POSITIVE_COLOR = '#87d068';
const NEGATIVE_COLOR = '#f50';

export interface BooleanMarkProps {
    value: boolean;
}

/**
 * Компонент отображения логической метки.
 */
export const BooleanMark = ({ value }: BooleanMarkProps) => {
    return value === true ? (
        <Tag color={POSITIVE_COLOR} className="boolean-mark-tag">
            <PlusOutlined />
        </Tag>
    ) : (
        <Tag color={NEGATIVE_COLOR} className="boolean-mark-tag">
            <MinusOutlined />
        </Tag>
    );
};
