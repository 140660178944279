import { InspectionStatus } from '@/shared/constants';
import { ClientType, PaginatedResult } from '@/shared/types';
import {
    INSPECTIONS_COMPLETED_TODAY_LIST_SIZE,
    MAX_NEW_INSPECTIONS_LOADED,
    MAX_WORK_INSPECTIONS_LOADED,
} from './constants';

export interface InspectionToday {
    id: number;

    createdAt: number;

    percentFill: number;

    raterStatus: InspectionStatus;

    appraiserStatus: InspectionStatus;

    worksheetNumber?: string;

    division: string;

    ownerType: string;

    clientType: ClientType;

    version: number;

    isJunk: boolean;

    createdFromChat: boolean;

    isAuction: boolean;
}

export interface NewInspectionTodayManager extends InspectionToday {
    appraiserId: number;

    appraiserLastName: string;

    brand: string;

    model: string;

    year: number;

    kilometrage: number;
}

export type NewInspectionTodayType = InspectionToday | NewInspectionTodayManager;

export const newInspectionTodayGuard = (
    inspectionToday: NewInspectionTodayType,
): inspectionToday is InspectionToday => {
    return !Object.hasOwn(inspectionToday, 'kilometrage');
};

export const newInspectionTodayManagerGuard = (
    inspectionToday: NewInspectionTodayType,
): inspectionToday is NewInspectionTodayManager => {
    return !!Object.hasOwn(inspectionToday, 'kilometrage');
};

export interface WorkInspectionToday extends InspectionToday {
    appraiserId: number;

    appraiserLastName: string;

    brand: string;

    model: string;

    year: number;

    kilometrage: number;
}

export interface CompletedInspectionToday extends InspectionToday {
    raterId: number;

    appraiserId: number;

    appraiserLastName: string;

    updatedAt: number;

    vin: string;

    brand?: string;

    model?: string;

    year?: number;

    salePrice?: number;

    kilometrage?: number;
}

export type InspectionTodayItem = InspectionToday | WorkInspectionToday | CompletedInspectionToday;

export interface TodayInspectionsResult<T = InspectionTodayItem> extends PaginatedResult<T> {
    list: T[];
    count: number;
    page: number;
}

export class TodayInspectionsParams {
    static forNew() {
        return new TodayInspectionsParams(1, MAX_NEW_INSPECTIONS_LOADED, InspectionStatus.NEW);
    }

    static forWork() {
        return new TodayInspectionsParams(1, MAX_WORK_INSPECTIONS_LOADED, InspectionStatus.WORK);
    }

    static forCompleted(currentPage: number) {
        return new TodayInspectionsParams(
            currentPage,
            INSPECTIONS_COMPLETED_TODAY_LIST_SIZE,
            InspectionStatus.COMPLETED,
        );
    }

    constructor(
        public readonly current: number,
        public readonly pageSize: number,
        public readonly inspectionStatus: InspectionStatus,
    ) {}

    toJSON(): this {
        return this;
    }
}
