export class AppRoute {
    static readonly Root = '/';
    static readonly Auth = '/auth';
    static readonly Home = '/home';
    static readonly Users = '/users';
    static readonly Clients = '/clients';
    static readonly ClientProfile = `${AppRoute.Clients}/profile/:id`;
    static readonly Profile = `${AppRoute.Users}/profile/:id`;
    static readonly InspectionView = '/inspections/view/:id';
    static readonly InspectionPrint = '/inspections/view/:id/print';
    static readonly InspectionsCompleted = '/inspections/completed';
    static readonly InspectionsManage = '/inspections/manage';
    static readonly InspectionsToday = '/inspections/today';
    static readonly CarHistory = `/analytics/:id`;
    static readonly CommonStatistic = `/common-statistic`;
    static readonly InspectionStatistic = `/inspection-statistic`;
    static readonly ScreensEditor = `/screens-editor`;
    static readonly AuctionManagement = `/auction-management`;
    static readonly CategoryManagement = `/category-management`;
    static readonly CarCategory = `/car-category/:id`;
    static readonly InspectionBids = `/inspection-bids`;
    static readonly Forbidden = `/forbidden`;
    static readonly NotFound = `/not-found`;

    static is(appRoute: string, path?: string | null): boolean {
        return path?.toLowerCase()?.startsWith(appRoute) ?? false;
    }

    static get all() {
        return [AppRoute.Root, AppRoute.Auth, AppRoute.Home, AppRoute.Profile];
    }

    static get guardedList() {
        return [AppRoute.Home, AppRoute.Profile];
    }

    static isGuarded(route: string): boolean {
        return AppRoute.guardedList.some((appRoute) => AppRoute.match(appRoute, route));
    }

    static match(routeOne: string, routeTwo: string): boolean {
        return routeOne.toLowerCase() === routeTwo.toLowerCase();
    }

    static getProfileUrl(userId: number): string {
        return AppRoute.Profile.replace(':id', String(userId));
    }
}
