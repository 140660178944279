/**
 * Дефекты крепления.
 */
export enum BindingDefectType {
    /**
     * Норма.
     */
    NONE = 'NONE',
    /**
     * Демонтировалось.
     */
    DEMOUNT = 'DEMOUNT',
}
