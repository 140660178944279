import { atom } from '@/shared/factory';
import { createEvent, sample } from 'effector';
import { closeSocketFx, initSocketFx } from '@/shared/api';
import { userSession } from '@/shared/user-session';
import { inspectionsTodayRealtimeModel, inspectionViewRealtimeModel } from './inspections';
import { notificationsRealtimeModel } from './notifications';

export const realtimeModel = atom(() => {
    const init = createEvent();
    const dispose = createEvent();

    sample({
        clock: init,
        filter: () => userSession.isActive,
        fn: () => userSession.authToken,
        target: initSocketFx,
    });

    sample({
        clock: dispose,
        target: closeSocketFx,
    });

    sample({
        clock: initSocketFx.doneData,
        target: [inspectionsTodayRealtimeModel.init, inspectionViewRealtimeModel.init, notificationsRealtimeModel.init],
    });

    return {
        init,
        dispose,
    };
});
