import { COMPLETED } from '@/shared/constants/inspection-status';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

/**
 * RBAC правила для Наблюдателя.
 */
export const WATCHER = {
    static: [
        RbacAction.HOME_PAGE_VISIT,
        RbacAction.INSPECTION_LIST,
        RbacAction.INSPECTION_COMPLETED_VIEW_RATER,
        RbacAction.INSPECTIONS_SUBSCRIBE,
        RbacAction.AUCTION_NOTIFICATION,
        RbacAction.INSPECTION_COMPLETED_VIEW_DIVISIONS,
    ],
    dynamic: {
        [RbacAction.INSPECTION_COMPLETED_VISIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.PROFILE_VISIT]: ({ data }) => {
            return data && Number(userSession.userId) === Number(data.id);
        },
        [RbacAction.PROFILE_UPDATE]: ({ data }) => {
            return data && Number(data.id) === Number(userSession.userId);
        },
    },
};
