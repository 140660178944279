import { type FetchOptions, ofetch } from 'ofetch';
import { CreateRequestParams, Payload, RequestError, RequestInstanceParams, RequestParams } from './types';
import { userSession } from '@/shared/user-session';

const AUTH_HEADER = 'Authorization';
const APP_VERSION_HEADER = 'app-version';
const DEVICE_ID_HEADER = 'device-id';
const DEVICE_TYPE_HEADER = 'device-type';

export const BASE_URL = import.meta.env.VITE_APP_API_GATEWAY;
export const BASE_STATISTICS_URL = import.meta.env.VITE_APP_STATISTICS_ENDPOINT;

function getConfig<P>(payload: Payload<P>, params: P): RequestParams {
    return typeof payload === 'function' ? payload(params) : payload;
}

function setAuthHeaders(headers: Headers): Headers {
    headers.append(AUTH_HEADER, `Bearer ${userSession.authToken}`);
    return headers;
}

function setDefaultHeaders(headers: Headers): Headers {
    headers.append(DEVICE_ID_HEADER, 'autoinspect_web');
    headers.append(DEVICE_TYPE_HEADER, 'web');
    headers.append(APP_VERSION_HEADER, import.meta.env.VITE_APP_VERSION);
    return headers;
}

function createRequestHeaders(headersInit: FetchOptions['headers'], withAuth = false): Headers {
    const requestHeaders = new Headers(headersInit);
    return setDefaultHeaders(withAuth ? setAuthHeaders(requestHeaders) : requestHeaders);
}

export function createRequestInstance<P = RequestParams, R = void, E = RequestError>({
    baseURL,
    headers,
    payload,
    withAuth,
}: RequestInstanceParams<P>) {
    return (params: P) => {
        const { url, ...fetchOptions } = getConfig(payload, params);
        return ofetch(url, {
            ...fetchOptions,
            headers: createRequestHeaders(headers, withAuth),
            baseURL,
        });
    };
}

function createRequest(params: CreateRequestParams) {
    return <P = RequestParams, R = void, E = RequestError>(payload: Payload<P>) => {
        return createRequestInstance({ ...(params as RequestParams), payload });
    };
}

export const createWithAuthRequest = createRequest({
    baseURL: BASE_URL,
    withAuth: true,
});
