/**
 * Дефекты герметика.
 */
export enum SealantDefectType {
    /**
     * Нет.
     */
    NONE = 'NONE',
    /**
     * Заводской.
     */
    FACTORY = 'FACTORY',
    /**
     * Не заводской.
     */
    NOT_FACTORY = 'NOT_FACTORY',
}
