import { InspectionToday, WorkInspectionToday } from '@/entities/inspection';
import { InspectionTodayRealtimeInfo } from '@/features/realtime/inspections/today/types';

export const reducerForNewInspectionsUpdate = (newList: InspectionToday[], { data }: InspectionTodayRealtimeInfo) => {
    const sameNewInspection = newList.find((item) => item.id === data.id);
    if (!sameNewInspection) {
        return newList;
    }

    const { percentFill, isJunk, isAuction } = data;
    const updatedInspection = { ...sameNewInspection, percentFill, isJunk, isAuction };
    const tmpNewList = newList.filter((item) => item.id !== updatedInspection.id);

    return [...tmpNewList, updatedInspection];
};

export const reducerForWorkInspectionsUpdate = (
    workList: WorkInspectionToday[],
    { data }: InspectionTodayRealtimeInfo,
) => {
    const sameWorkInspection = workList.find((item) => item.id === data.id);
    if (!sameWorkInspection) {
        return workList;
    }

    const updatedInspection = { ...sameWorkInspection, percentFill: data.percentFill };
    const tmpWorkList = workList.filter((item) => item.id !== updatedInspection.id);

    return [...tmpWorkList, updatedInspection];
};
