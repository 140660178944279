/**
 * Дефекты окрашенного элемента.
 */
export enum PaintedElementDefectType {
    /**
     * Шпаклёвка.
     */
    PUTTY = 'PUTTY',
    /**
     * Некачественный окрас.
     */
    DEFECTIVE_COLOR = 'DEFECTIVE_COLOR',
    /**
     * Была замена детали.
     */
    PART_REPLACEMENT = 'PART_REPLACEMENT',
    /**
     * Косметический ремонт.
     */
    COSMETIC_REPAIR = 'COSMETIC_REPAIR',
    /**
     * Нет дефектов.
     */
    NONE = 'NONE',
}
