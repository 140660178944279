import { UserRole } from '@/shared/constants/user-role';
import { COMPLETED } from '@/shared/constants/inspection-status';
import { RbacAction } from './actions';
import { userSession } from '@/shared/user-session';

const { APPRAISER } = UserRole;

/**
 * RBAC правила для Руководителя прайсеров - РДО (Руководитель дистанционной оценки).
 */
export const APPRAISER_MANAGER = {
    static: [
        RbacAction.HOME_PAGE_VISIT,
        RbacAction.MANAGE,
        RbacAction.CLIENT_LIST,
        RbacAction.CLIENT_VISIT,
        RbacAction.CLIENT_CREATE,
        RbacAction.CLIENT_MANAGE,
        RbacAction.USER_LIST,
        RbacAction.USER_CREATE,
        RbacAction.INSPECTION_LIST,
        RbacAction.INSPECTION_VISIT,
        RbacAction.INSPECTION_MANAGE,
        RbacAction.INSPECTION_MANAGE_VIEW,
        RbacAction.INSPECTION_INSPECTION_INFO_UPDATE,
        RbacAction.INSPECTIONS_TODAY_VISIT,
        RbacAction.INSPECTIONS_TODAY_MANAGER_MODE,
        RbacAction.INSPECTION_COMPLETED_VIEW_APPRAISER,
        RbacAction.INSPECTION_COMPLETED_VIEW_DIVISIONS,
        RbacAction.INSPECTIONS_SUBSCRIBE,
        RbacAction.SET_USER_DIVISION,
        RbacAction.SHOW_USER_DIVISION,
        RbacAction.STATISTIC_VIEW,
        RbacAction.CAR_CATEGORY_MANAGE,
        RbacAction.CAR_CATEGORY_VISIT,
        RbacAction.INSPECTION_BID_LIST,
        RbacAction.CAR_HISTORY_VISIT,
        RbacAction.CAR_HISTORY_BUILD,
    ],
    dynamic: {
        [RbacAction.INSPECTION_COMPLETED_VISIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.PROFILE_VISIT]: ({ data }) => {
            return data && (Number(userSession.userId) === Number(data.id) || data.role === APPRAISER);
        },
        [RbacAction.ACCOUNT_UPDATE]: ({ data }) => {
            return data && data.role === APPRAISER;
        },
        [RbacAction.PROFILE_UPDATE]: ({ data }) => {
            return data && (data.role === APPRAISER || Number(data.id) === Number(userSession.userId));
        },
        [RbacAction.PASSWORD_UPDATE]: (data) => {
            return data && data.role === APPRAISER;
        },
        [RbacAction.USER_REMOVE]: ({ data }) => {
            return data && data.role === APPRAISER;
        },
        [RbacAction.INSPECTION_DOCUMENTS_EDIT]: (status) => {
            return status === COMPLETED;
        },
        [RbacAction.INSPECTION_CAR_EDIT]: (status) => {
            return status === COMPLETED;
        },
    },
};
