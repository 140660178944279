import React, { Component } from 'react';
import { CheckOutlined, CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { Input, message, Tooltip } from 'antd';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import './style.css';

const READY_COPY_ICON = 'copy';
const COPYING_ICON = 'check';
const COPY_TIMEOUT = 2000;

export interface PasswordFieldProps {
    onGenerate?: (val: string) => void;
}

/**
 * Компонент поля ввода пароля.
 */
export class PasswordField extends Component<PasswordFieldProps> {
    static propTypes = {
        onGenerate: PropTypes.func,
    };

    state = {
        password: '',
        copyIcon: READY_COPY_ICON,
        hasClipboard: true,
    };

    componentDidMount() {
        if (!navigator.clipboard) {
            this.setState({ hasClipboard: false });
        }
    }

    componentWillUnmount() {
        this.setState({ password: '' });
    }

    renderClipboard = () => {
        const className = this.state.password ? this.state.copyIcon : 'disabled';
        return (
            <Tooltip title="Скопировать пароль">
                <span className={`password-field-addon-icon-${className}`} onClick={this.handleCopyPassword}>
                    {this.state.copyIcon === COPYING_ICON ? <CheckOutlined /> : <CopyOutlined />}
                </span>
            </Tooltip>
        );
    };

    handleGeneratePassword = (onGenerate) => {
        const password = nanoid(12);
        this.setState({ password }, () => {
            if (onGenerate) {
                onGenerate(password);
            }
        });
    };

    handleCopyPassword = () => {
        if (this.state.password) {
            navigator.clipboard
                .writeText(this.state.password)
                .then(() => {
                    this.setState({ copyIcon: COPYING_ICON }, () => {
                        const timerId = setTimeout(() => {
                            this.setState({ copyIcon: READY_COPY_ICON });
                            clearTimeout(timerId);
                        }, COPY_TIMEOUT);
                    });
                })
                .catch(() => {
                    void message.error('Ошибка копирования пароля');
                });
        }
    };

    handleInput = (e) => {
        this.setState({ password: e.target.value });
    };

    render() {
        const { onGenerate, ...props } = this.props;

        return (
            <Input.Password
                {...props}
                value={this.state.password}
                onInput={this.handleInput}
                type="password"
                addonAfter={
                    <span>
                        <Tooltip title="Сгенерировать пароль">
                            <span
                                className="password-field-addon-icon-password"
                                onClick={() => this.handleGeneratePassword(onGenerate)}
                            >
                                <SyncOutlined />
                            </span>
                        </Tooltip>
                        {this.state.hasClipboard && this.renderClipboard()}
                    </span>
                }
            />
        );
    }
}
