/**
 * Вид покупки.
 */
export class PurchaseType {
    static TradeIn = 'Tradein';
    static Commission = 'Комиссия';
    static Auction = 'Аукцион';
    static AuctionB2B = 'АукционB2B';
    static Buyout = 'Выкуп';
    static DirectBuyout = 'ПрямойВыкуп';
    static BuyoutCDC = 'ВыкупЦДЦ';
    static ExitBuyout = 'ВыезднойВыкуп';
    static Refund = 'Возврат';
    static AgencyContract = 'АгентскийДоговор';
    static Secondhand = 'БУнаБУ';
    static Dealer = 'Дилер';
    static BrokerTransaction = 'БрокерскаяСделка';

    static get list() {
        const {
            TradeIn,
            Commission,
            Auction,
            AuctionB2B,
            Buyout,
            DirectBuyout,
            BuyoutCDC,
            ExitBuyout,
            Refund,
            AgencyContract,
            Secondhand,
            Dealer,
            BrokerTransaction,
        } = PurchaseType;

        return [
            new PurchaseType(TradeIn, 'TradeIn'),
            new PurchaseType(Commission, 'Комиссия'),
            new PurchaseType(Auction, 'Аукцион'),
            new PurchaseType(AuctionB2B, 'Аукцион B2B'),
            new PurchaseType(Buyout, 'Выкуп'),
            new PurchaseType(DirectBuyout, 'Прямой Выкуп'),
            new PurchaseType(BuyoutCDC, 'Выкуп ЦДЦ'),
            new PurchaseType(ExitBuyout, 'Выездной Выкуп'),
            new PurchaseType(Refund, 'Возврат'),
            new PurchaseType(AgencyContract, 'Агентский договор'),
            new PurchaseType(Secondhand, 'БУ/БУ'),
            new PurchaseType(Dealer, 'Дилер'),
            new PurchaseType(BrokerTransaction, 'Брокерская сделка'),
        ];
    }

    static get filterableList() {
        return PurchaseType.list.map(({ id: value, label: text }) => ({ text, value }));
    }

    static getLabel(id) {
        const type = PurchaseType.list.find((type) => type.id === id);
        return (type && type.label) || 'n/a';
    }

    id: string;
    label: string;

    constructor(id, label) {
        this.id = id;
        this.label = label;
    }
}
