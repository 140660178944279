/**
 * Категория авто.
 */
export class CarCategory {
    static A = 'A';
    static B = 'B';
    static C = 'C';
    static D = 'D';

    static get all() {
        return [
            new CarCategory(CarCategory.A, '#79c14f'),
            new CarCategory(CarCategory.B, '#557b9f'),
            new CarCategory(CarCategory.C, '#e79d49'),
            new CarCategory(CarCategory.D, '#f7864e'),
        ];
    }

    static getByName(name, defaultCategory = new CarCategory()) {
        const categoryName = name?.toUpperCase();
        return CarCategory.all.find(({ name }) => name === categoryName) ?? defaultCategory;
    }

    name: string;
    color: string;

    constructor(name = 'N/A', color = '#5d6060') {
        this.name = name;
        this.color = color;
    }
}
