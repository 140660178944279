import React from 'react';
import { DANGER_COLOR, OK_COLOR, WARNING_COLOR } from '@/shared/constants';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { mapErasedWheelDefects } from '@/shared/lib/defects.config';
import { ErasedWheelDefectType } from '@/shared/enums/defects';

const ERASED_DEFECTS = {
    /**
     * Высокий.
     */
    [ErasedWheelDefectType.HIGHT]: {
        title: mapErasedWheelDefects(ErasedWheelDefectType.HIGHT),
        color: DANGER_COLOR,
        icon: ExclamationCircleOutlined,
    },
    /**
     * Средний.
     */
    [ErasedWheelDefectType.MEDIUM]: {
        title: mapErasedWheelDefects(ErasedWheelDefectType.MEDIUM),
        color: WARNING_COLOR,
        icon: ExclamationCircleOutlined,
    },
    /**
     * Малый.
     */
    [ErasedWheelDefectType.LOW]: {
        title: mapErasedWheelDefects(ErasedWheelDefectType.LOW),
        color: OK_COLOR,
        icon: CheckCircleOutlined,
    },
};

export interface ErasedFieldProps {
    data?: keyof typeof ERASED_DEFECTS;
    hideIcon?: boolean;
}

/**
 * Уровень износа.
 */
export const ErasedField = ({ data, hideIcon }: ErasedFieldProps) => {
    if (!data) return null;

    const defect = ERASED_DEFECTS[data];
    return defect ? (
        <div className="defect-field-item">
            {!hideIcon && <defect.icon style={{ color: defect.color }} className="defect-label-icon" />}
            <span className="defect-item-title">Уровень износа:&nbsp;</span>
            <span className="defect-item-value" style={{ color: defect.color }}>
                {defect.title}
            </span>
        </div>
    ) : null;
};

ErasedField.defaultProps = {
    hideIcon: false,
};
