import React, { forwardRef, PropsWithRef, useEffect, useState } from 'react';
import Input from 'antd/es/input';
import { InputRef } from 'antd';

const isNumeric = new RegExp(/^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/);

export interface NumericInputProps extends PropsWithRef<HTMLInputElement> {
    value: string;
    allowClear?: boolean;
}

/**
 * Input компонент ввода цифровых значений.
 */
export const NumericInput = forwardRef(function NumericInput(
    { value: propsValue, ...props }: NumericInputProps,
    ref: React.LegacyRef<InputRef>,
) {
    const [value, setValue] = useState('');

    useEffect(() => {
        filterValue(propsValue);
    }, [propsValue]);

    const filterValue = (value) => {
        if ((!Number(isNaN(value)) && isNumeric.test(value)) || value === '-' || value === '') {
            setValue(value);
        }
    };

    const onInput = ({ target }) => filterValue(target.value);

    // @ts-expect-error заменить на использование нового формата ref
    return <Input {...props} value={value} onInput={onInput} ref={ref} />;
});
